import React from 'react';
import './header.scss';
//@ts-ignore
import {ReactComponent as KoalafiLogo} from '../../assets/icons/koalafi-logo.svg';
import {typedState} from '../../store';
import {RolePermissions, viewRolePermissions} from '../../types';
import {TooltipWrapper} from '../tooltip';
import {Navbar, Nav, NavDropdown} from 'react-bootstrap';
import CustomerSearchBox from '../customer-search-box';
import CustomerSearchResultsModal from '../customer-search-results-modal';
import {searchForCustomers} from '../../shared';

const Header = () => {
	const auth = typedState((state) => state.auth);
	const applications = typedState((state) => state.applications);

	const currentDealerRedirectPath =
		`https://${auth.dealerPortalUrl}` +
		(auth.user?.Store
			? '/StoresList/StoreDetails'
			: auth.user?.Group
			? '/GroupsList/GroupDetails'
			: auth.user?.Company
			? '/CompaniesList/CompanyDetails'
			: '');

	const customerSearch = (
		event: React.ChangeEvent<HTMLInputElement> | React.FormEvent,
		customerSearchValue: string,
	) => {
		const searchValue = customerSearchValue
			? customerSearchValue
			: applications.searchCustomerAppsResult?.SearchValue;
		searchForCustomers(event, searchValue, 1);
	};

	return (
		<>
			<Navbar className={'header'} collapseOnSelect expand="lg">
				<Navbar.Brand className={'koalafi-logo'} href={`https://${auth.dealerPortalUrl}`}>
					<KoalafiLogo title="Koalafi Company Logo" role="img" />
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="responsive-navbar-nav" className="navbar-toggler">
					<i className="icon-menu" />
				</Navbar.Toggle>
				<Navbar.Collapse id="responsive-navbar-nav" className="burger-menu">
					<Nav className="mx-auto">
						{viewRolePermissions
							.get(RolePermissions.ShowHomePage)
							?.has(auth.user!.role) && (
							<div className={'item'}>
								<Nav.Link
									className="nav-link"
									href={`https://${auth.dealerPortalUrl}`}
								>
									<i className="icon-home" />
									<span>Home</span>
								</Nav.Link>
							</div>
						)}

						{viewRolePermissions
							.get(RolePermissions.ShowDashboard)
							?.has(auth.user!.role) && (
							<div className={'item'}>
								<a
									className="nav-link"
									href={`https://${auth.dealerPortalUrl}/DealerCabinet/Dashboard`}
								>
									<i className="icon-grid" />
									<span>Dashboard</span>
								</a>
							</div>
						)}
						{viewRolePermissions
							.get(RolePermissions.ShowDealers)
							?.has(auth.user!.role) && (
							<div className="item">
								<NavDropdown
									className="dropdown"
									title={
										<>
											<i className="icon-briefcase" />
											<span>Dealers</span>
										</>
									}
									id="dealer-nav-dropdown"
								>
									<NavDropdown.Item
										className="nav-link"
										href={`https://${auth.dealerPortalUrl}/StoresList/Index`}
									>
										Stores
									</NavDropdown.Item>
									{viewRolePermissions
										.get(RolePermissions.ShowGroupsAndCompanies)
										?.has(auth.user!.role) && (
										<NavDropdown.Item
											className="nav-link"
											href={`https://${auth.dealerPortalUrl}/GroupsList/Index`}
										>
											Groups
										</NavDropdown.Item>
									)}
									{viewRolePermissions
										.get(RolePermissions.ShowGroupsAndCompanies)
										?.has(auth.user!.role) && (
										<NavDropdown.Item
											className="nav-link"
											href={`https://${auth.dealerPortalUrl}/CompaniesList/Index`}
										>
											Companies
										</NavDropdown.Item>
									)}
								</NavDropdown>
							</div>
						)}

						{viewRolePermissions
							.get(RolePermissions.ShowReports)
							?.has(auth.user!.role) && (
							<div className={'item'}>
								<a
									className="nav-link"
									href={`https://${auth.dealerPortalUrl}/Reports/Index`}
								>
									<i className="icon-file-text" />
									<span>Reports</span>
								</a>
							</div>
						)}
					</Nav>

					<Nav className="ml-auto">
						<div className="item">
							<CustomerSearchBox isHeader onSubmit={customerSearch} />
						</div>
						<div className={'item'}>
							<a className="nav-link" href={'https://help.koalafi.com/en/'}>
								<TooltipWrapper text="Help" id="navbar-help-tooltip">
									<i className="icon-help-circle" />
								</TooltipWrapper>
								<span className="d-lg-none">Help</span>
							</a>
						</div>
						{viewRolePermissions
							.get(RolePermissions.ShowSettings)
							?.has(auth.user!.role) && (
							<div className={'item'}>
								<a
									className="nav-link"
									href={`https://${auth.dealerPortalUrl}/Settings/Index`}
								>
									<TooltipWrapper text={'Settings'} id="navbar-settings-tooltip">
										<i className="icon-settings" />
									</TooltipWrapper>
									<span className="d-lg-none">Settings</span>
								</a>
							</div>
						)}
						{viewRolePermissions
							.get(RolePermissions.ShowCurrentDealer)
							?.has(auth.user!.role) && (
							<div className={'item'}>
								<a className="nav-link" href={currentDealerRedirectPath}>
									<TooltipWrapper
										text={
											auth.user?.Store
												? 'My Store'
												: auth.user?.Group
												? 'My Group'
												: auth.user?.Company
												? 'My Company'
												: ''
										}
										id="navbar-dealer-tooltip"
									>
										<i className="icon-settings" />
									</TooltipWrapper>
									<span className="d-lg-none">
										{auth.user?.Store
											? 'My Store'
											: auth.user?.Group
											? 'My Group'
											: auth.user?.Company
											? 'My Company'
											: ''}
									</span>
								</a>
							</div>
						)}
						<div className={'item'}>
							<a
								className="nav-link"
								href={`https://${auth.dealerPortalUrl}/Account/LogOffExternal`}
							>
								<TooltipWrapper text={'Log Out'} id="navbar-logout-tooltip">
									<i className="icon-log-out" />
								</TooltipWrapper>
								<span className="d-lg-none">Log Out</span>
							</a>
						</div>
					</Nav>
				</Navbar.Collapse>
			</Navbar>
			<CustomerSearchResultsModal />
		</>
	);
};

export default Header;
