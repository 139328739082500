enum Role {
	Customer = 'Customer',
	Dealer = 'Dealer',
	Admin = 'Admin',
	CSR = 'CSR',
	DealerSupportRep = 'DealerSupportRep',
	SalesVP = 'SalesVP',
	SalesRep = 'SalesRep',
	Company = 'Company',
	Group = 'Group',
	Store = 'Store',
	Storis = 'Storis',
	SalesManager = 'SalesManager',
	RapidSystem = 'RapidSystem',
	CSRManager = 'CSRManager',
	Trainer = 'Trainer',
	Farmer = 'Farmer',
	TrainingStore = 'TrainingStore',
}

enum RolePermissions {
	ShowCurrentDealer = 'ShowCurrentDealer',
	ShowDealers = 'ShowDealers',
	ShowFindCustomer = 'ShowFindCustomer',
	ShowGroupsAndCompanies = 'ShowGroupsAndCompanies',
	ShowHomePage = 'ShowHomePage',
	ShowReports = 'ShowReports',
	ShowSettings = 'ShowSettings',
	ShowDashboard = 'ShowDashboard',
	ShowHelp = 'ShowHelp',
	ShowFindCustomerCanSeeLinkToRain = 'ShowFindCustomerCanSeeLinkToRain',
	ShowUpdatePurchaseAmount = 'ShowUpdatePurchaseAmount',
	ShowResumeButton = 'ShowResumeButton',
	ShowLoanProButton = 'ShowLoanProButton',
	ShowResendAcknowledgementButton = 'ShowResendAcknowledgementButton',
	ShowAcknowledgeTermsButton = 'ShowAcknowledgeTermsButton',
	ShowUpdatePrincipalButton = 'ShowUpdatePrincipalButton',
	ShowMarkAsFundedButton = 'ShowMarkAsFundedButton',
	ShowItemsDelivered = 'ShowItemsDelivered',
	AllowEditLoanStatus = 'AllowEditLoanStatus',
	ShowLoanDetails = 'ShowLoanDetails',
	ShowHomeOwnershipButton = 'ShowHomeOwnershipButton',
}

const showCurrentDealerRolesSet = new Set<Role>([Role.Company, Role.Group, Role.Store]);

const showDealersRolesSet = new Set<Role>([
	Role.Admin,
	Role.DealerSupportRep,
	Role.SalesManager,
	Role.SalesRep,
	Role.SalesVP,
	Role.Trainer,
	Role.Farmer,
]);
const showFindCustomerRolesSet = new Set<Role>([
	Role.Admin,
	Role.DealerSupportRep,
	Role.SalesManager,
	Role.SalesRep,
	Role.SalesVP,
	Role.CSR,
	Role.CSRManager,
	Role.Company,
	Role.Group,
	Role.Store,
	Role.Farmer,
]);
const showGroupsAndCompaniesRolesSet = new Set<Role>([
	Role.Admin,
	Role.DealerSupportRep,
	Role.SalesManager,
	Role.SalesRep,
	Role.SalesVP,
	Role.Trainer,
]);
const showHomePageRolesSet = new Set<Role>([
	Role.SalesManager,
	Role.SalesRep,
	Role.SalesVP,
	Role.CSR,
	Role.CSRManager,
	Role.Store,
]);
const showReportsRolesSet = new Set<Role>([
	Role.Admin,
	Role.DealerSupportRep,
	Role.SalesManager,
	Role.SalesRep,
	Role.SalesVP,
	Role.CSR,
	Role.CSRManager,
	Role.Company,
	Role.Group,
	Role.Store,
]);
const showHelpRolesSet = new Set<Role>([
	Role.Admin,
	Role.DealerSupportRep,
	Role.SalesManager,
	Role.SalesRep,
	Role.SalesVP,
	Role.CSR,
	Role.CSRManager,
	Role.Company,
	Role.Group,
	Role.Store,
]);
const showSettingsRolesSet = new Set<Role>([Role.Admin, Role.DealerSupportRep]);

const showDashboardRolesSet = new Set<Role>([
	Role.Admin,
	Role.DealerSupportRep,
	Role.SalesManager,
	Role.SalesRep,
	Role.SalesVP,
	Role.Company,
	Role.Group,
	Role.Store,
	Role.Farmer,
]);

const showFindCustomerCanSeeLinkToRainSet = new Set<Role>([Role.CSR, Role.CSRManager]);

const showLoanDetails = new Set<Role>([
	Role.Admin,
	Role.Store,
	Role.DealerSupportRep,
	Role.Company,
	Role.Group,
	Role.SalesVP,
	Role.SalesRep,
	Role.CSR,
	Role.CSRManager,
	Role.SalesManager,
	Role.Farmer,
]);

const showUpdatePurchasedAmount = new Set<Role>([Role.Admin, Role.DealerSupportRep, Role.Store]);

const showResumeButton = new Set<Role>([Role.Store, Role.Company, Role.Admin]);

const showLoanProButton = new Set<Role>([Role.Admin, Role.CSR, Role.DealerSupportRep]);

const showShowResendAcknowledgementButton = new Set<Role>([
	Role.Admin,
	Role.CSR,
	Role.DealerSupportRep,
	Role.Store,
]);

const showAcknowledgeTermsButton = new Set<Role>([Role.Admin, Role.CSR, Role.DealerSupportRep]);

const showUpdatePrincipalButton = new Set<Role>([Role.Admin, Role.DealerSupportRep, Role.Store]);

const showMarkAsFundedButton = new Set<Role>([Role.Admin, Role.DealerSupportRep]);

const showItemsDelivered = new Set<Role>([
	Role.Admin,
	Role.DealerSupportRep,
	Role.Company,
	Role.Group,
	Role.Store,
]);

const showHomeOwnershipButton = new Set<Role>([Role.Admin]);

const allowEditLoanStatus = new Set<Role>([Role.Admin, Role.DealerSupportRep]);

const viewRolePermissions = new Map<RolePermissions, Set<Role>>([
	[RolePermissions.ShowCurrentDealer, showCurrentDealerRolesSet],
	[RolePermissions.ShowDealers, showDealersRolesSet],
	[RolePermissions.ShowFindCustomer, showFindCustomerRolesSet],
	[RolePermissions.ShowGroupsAndCompanies, showGroupsAndCompaniesRolesSet],
	[RolePermissions.ShowHomePage, showHomePageRolesSet],
	[RolePermissions.ShowReports, showReportsRolesSet],
	[RolePermissions.ShowSettings, showSettingsRolesSet],
	[RolePermissions.ShowDashboard, showDashboardRolesSet],
	[RolePermissions.ShowHelp, showHelpRolesSet],
	[RolePermissions.ShowFindCustomerCanSeeLinkToRain, showFindCustomerCanSeeLinkToRainSet],
	[RolePermissions.ShowUpdatePurchaseAmount, showUpdatePurchasedAmount],
	[RolePermissions.ShowResumeButton, showResumeButton],
	[RolePermissions.ShowLoanProButton, showLoanProButton],
	[RolePermissions.ShowResendAcknowledgementButton, showShowResendAcknowledgementButton],
	[RolePermissions.ShowAcknowledgeTermsButton, showAcknowledgeTermsButton],
	[RolePermissions.ShowUpdatePrincipalButton, showUpdatePrincipalButton],
	[RolePermissions.ShowMarkAsFundedButton, showMarkAsFundedButton],
	[RolePermissions.ShowItemsDelivered, showItemsDelivered],
	[RolePermissions.AllowEditLoanStatus, allowEditLoanStatus],
	[RolePermissions.ShowLoanDetails, showLoanDetails],
	[RolePermissions.ShowHomeOwnershipButton, showHomeOwnershipButton],
]);

export interface User {
	role: Role;
	name: string;
	Store?: string;
	Group?: string;
	Company?: string;
	DealerCompany?: string;
}

export {Role, RolePermissions, viewRolePermissions};
