import React, {FC} from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {OverlayTriggerRenderProps} from 'react-bootstrap/OverlayTrigger';
import './tooltip.scss';

interface P {
	text: string;
	id: string;
	children: React.ReactElement | ((props: OverlayTriggerRenderProps) => React.ReactNode);
}

const TooltipWrapper: FC<P> = ({text, children, id}) => {
	return (
		<OverlayTrigger
			trigger={['hover', 'focus']}
			placement={'bottom'}
			overlay={<Tooltip id={id}>{text}</Tooltip>}
			defaultShow={false}
			delay={0}
			flip={false}
			onHide={undefined}
			onToggle={undefined}
			popperConfig={undefined}
			show={undefined}
			target={undefined}
		>
			{children}
		</OverlayTrigger>
	);
};

export default TooltipWrapper;
