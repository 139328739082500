import React, {useState, useEffect} from 'react';
import './table-pagination.scss';
import {Pagination} from 'react-bootstrap';
import {PageInfo} from '../../types/search-apps';

interface PaginationInfo {
	pageNumber: number;
	totalPages: number;
	totalItems: number;
	itemsPerPage: number;
}

interface TablePaginationProps {
	pageInfo: PageInfo | undefined;
	handlePageChange: Function;
	className?: string;
}

const TablePagination: React.FC<TablePaginationProps> = ({
	pageInfo,
	handlePageChange,
	className,
}) => {
	const [pageResultsInfo, setPageResultsInfo] = useState<PaginationInfo>({
		pageNumber: pageInfo ? pageInfo.PageNumber : 1,
		totalPages: pageInfo ? pageInfo.TotalPages : 1,
		totalItems: pageInfo ? pageInfo.TotalItems : 1,
		itemsPerPage: pageInfo ? pageInfo.PageSize : 5,
	});
	const [isShowPageNumberSelection, setIsShowPageNumberSelection] = useState<boolean>(false);
	const [pageNumberSelection, setPageNumberSelection] = useState<number>(1);
	const [elipKey, setElipKey] = useState<number>();
	const pagesCount = Math.ceil(pageResultsInfo.totalItems / pageResultsInfo.itemsPerPage);
	const isCurrentPageFirst = pageResultsInfo.pageNumber === 1;
	const isCurrentPageLast = pageResultsInfo.pageNumber === pageInfo?.TotalPages;

	const changePage = (
		event: React.MouseEvent<HTMLElement> | React.FocusEvent<HTMLInputElement>,
		number: number,
	): void => {
		if (pageResultsInfo.pageNumber === number) return;
		handlePageChange(event, null, number);
	};

	const onPageNumberClick = (e: React.MouseEvent<HTMLElement>, pageNumber: number): void => {
		changePage(e, pageNumber);
	};

	const onPreviousPageClick = (e: React.MouseEvent<HTMLElement>): void => {
		const currentPage = pageResultsInfo.pageNumber - 1;
		changePage(e, currentPage);
	};

	const onNextPageClick = (e: React.MouseEvent<HTMLElement>): void => {
		const currentPage = pageResultsInfo.pageNumber + 1;
		changePage(e, currentPage);
	};

	const setLastPageAsCurrent = (): void => {
		if (pageResultsInfo.pageNumber > pagesCount) {
			handlePageChange(pagesCount);
		}
	};

	const onPageNumberBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
		setIsShowPageNumberSelection(false);
		changePage(e, Number(e.target.value));
	};

	const onElipClick = (e: React.MouseEvent<HTMLElement>, pNum: number): void => {
		e.preventDefault();
		setIsShowPageNumberSelection(true);
		setPageNumberSelection(pNum);
		setElipKey(pNum);
		setTimeout(() => {
			document.getElementById(`pageNumberSelection${pNum}`)?.focus();
		}, 200);
	};

	const onPageNumberChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		const pageNumber = Number(e.target.value);
		setPageNumberSelection(pageNumber);
	};

	let isPageNumberOutOfRange: boolean;

	const pageNumbers = [...new Array(pageInfo?.TotalPages)].map((_, index): JSX.Element | null => {
		const pageNumber = index + 1;
		const isPageNumberFirst = pageNumber === 1;
		const isPageNumberLast = pageNumber === pageInfo?.TotalPages;
		const isCurrentPageWithinTwoPageNumbers =
			Math.abs(pageNumber - pageResultsInfo.pageNumber) < 2;

		if (isPageNumberFirst || isPageNumberLast || isCurrentPageWithinTwoPageNumbers) {
			isPageNumberOutOfRange = false;
			return (
				<Pagination.Item
					key={pageNumber}
					onClick={(e) => onPageNumberClick(e, pageNumber)}
					active={pageNumber === pageResultsInfo.pageNumber}
				>
					{pageNumber}
				</Pagination.Item>
			);
		}

		if (!isPageNumberOutOfRange) {
			isPageNumberOutOfRange = true;
			return isShowPageNumberSelection && pageNumber === elipKey ? (
				<Pagination.Item
					key={pageNumber}
					active={pageNumber === pageResultsInfo.pageNumber}
				>
					<input
						type="number"
						min={1}
						max={pageInfo?.TotalPages}
						value={pageNumberSelection}
						step={1}
						id={`pageNumberSelection${pageNumber}`}
						onChange={onPageNumberChange}
						onBlur={(e) => onPageNumberBlur(e)}
					/>
				</Pagination.Item>
			) : (
				<Pagination.Ellipsis
					key={pageNumber}
					className="muted"
					onClick={(e) => onElipClick(e, pageNumber)}
				/>
			);
		}

		return null;
	});

	useEffect(setLastPageAsCurrent, [pagesCount]);

	useEffect(() => {
		const results = {
			pageNumber: pageInfo ? pageInfo.PageNumber : 1,
			totalPages: pageInfo ? pageInfo.TotalPages : 1,
			totalItems: pageInfo ? pageInfo.TotalItems : 1,
			itemsPerPage: pageInfo ? pageInfo.PageSize : 5,
		};
		setPageResultsInfo(results);
	}, [pageInfo]);

	return (
		<Pagination className={className}>
			<Pagination.Prev onClick={onPreviousPageClick} disabled={isCurrentPageFirst} />
			{pageNumbers}
			<Pagination.Next onClick={onNextPageClick} disabled={isCurrentPageLast} />
		</Pagination>
	);
};

export default TablePagination;
