import React, {useState} from 'react';
import includes from 'lodash.includes';
import qs from 'query-string';
import {typedState} from '../../store';
import {Errors, Role, RolePermissions, viewRolePermissions} from '../../types';
import {LoanStatus} from '../../components/loan-status';
import {AppOverview} from '../../components/app-overview';
import {AppLabel, AppStatus} from '../../types/applications';
import {ApplicationDetailsSidebar} from '../../components/app-details-sidebar';
import {Modal} from '../../components/modal';
import {UpdatePrincipalModal} from '../../components/update-principal-modal';
import './loan-details.scss';
import {ErrorModal} from '../../components/modal/error-modal';
import {resendLoanAcknowledgement, updateApplicationToFunded} from '../../apis';
import {Button} from '../../components/button';
import {HomeOwnershipModal} from '../../components/homeownership-modal';
import {track} from '../../utils';

const LoanDetails = () => {
	// Global State
	const appData = typedState((state) => state.applications.application);
	const loanData = typedState((state) => state.loans.loan);
	const role = typedState((state) => state.auth.user?.role);
	const store = typedState((state) => state.stores.store);
	const auth = typedState((state) => state.auth);

	const [markAsFundedLoading, setMarkAsFundedLoading] = useState(false);
	const [resendAcknowledgementLoading, setResendAcknowledgementLoading] = useState(false);
	const [showPrincipalUpdateModal, setShowPrincipalUpdateModal] = useState(false);
	const [showHomeOwnershipModal, setShowHomeOwnershipModal] = useState(false);
	const [error, setError] = useState<Errors | null>(null);

	if (!viewRolePermissions.get(RolePermissions.ShowLoanDetails)?.has(role!)) {
		window.location.replace(`https://${auth.dealerPortalUrl}`);
		return null;
	}
	const canEditLoanStatus = () => {
		return viewRolePermissions.get(RolePermissions.AllowEditLoanStatus)?.has(role!);
	};

	const renderLoanProButton = () => {
		const allowedStatuses: AppStatus[] = [
			AppStatus.Accepted,
			AppStatus.Delivered,
			AppStatus.Funded,
		];

		if (
			viewRolePermissions.get(RolePermissions.ShowLoanProButton)?.has(role!) &&
			includes(allowedStatuses, appData?.application.status)
		) {
			return (
				<div className="button-wrapper">
					<Button
						className="button"
						onClick={() => {
							window.open(
								appData?.application.loanId
									? `${process.env.REACT_APP_LOANPRO_URL}/profile?loanid=${appData?.application.loanId}&view=summary`
									: process.env.REACT_APP_LOANPRO_URL,
							);
						}}
						size="standard"
					>
						LoanPro
					</Button>
				</div>
			);
		}
		return null;
	};

	const renderResumeButton = () => {
		const allowedStatuses: AppStatus[] = [
			AppStatus.Approved,
			AppStatus.ErrorsFound,
			AppStatus.Draft,
			AppStatus.DataRequested,
		];
		const isOobaWorkaroundApplied: Boolean =
			appData?.application.status === AppStatus.DocsReady &&
			!!appData.application.acknowledgedBy &&
			appData.application.acknowledgedBy !== Role.Customer;
		if (
			viewRolePermissions.get(RolePermissions.ShowResumeButton)?.has(role!) &&
			appData?.application.label !== AppLabel.ECommerce &&
			(includes(allowedStatuses, appData?.application.status) || isOobaWorkaroundApplied)
		) {
			const queryStringVars = {
				dealerId: store?.publicId,
				applicationId: appData?.application.id,
			};

			return (
				<div className="button-wrapper">
					<Button
						className="button"
						onClick={() => {
							window.location.href = `${
								process.env.REACT_APP_LOANS_UI_URL
							}?${qs.stringify(queryStringVars)}`;
						}}
						size="standard"
						disabled={isEcommerce()}
					>
						Resume Application
					</Button>
				</div>
			);
		}
		return null;
	};

	const renderResendAcknowledgementButton = () => {
		const loanExists = loanData && loanData.id;
		const isOobaAcknowledgementRequired: Boolean =
			appData?.application.status === AppStatus.DocsReady &&
			!appData.application.acknowledgedBy;
		if (
			viewRolePermissions.get(RolePermissions.ShowResendAcknowledgementButton)?.has(role!) &&
			loanExists &&
			isOobaAcknowledgementRequired
		) {
			const handleResendAcknowledgement = async () => {
				setResendAcknowledgementLoading(true);
				track('OOBA Resent', {
					additionalProperties: {
						applicationId: appData?.application.id,
						apptype: 'loan',
					},
				});
				const error = await resendLoanAcknowledgement(
					store!.publicId,
					appData!.application.id,
				);

				if (error) {
					setError(error);
				}
				setResendAcknowledgementLoading(false);
			};

			return (
				<div className="button-wrapper">
					<Button
						className="button"
						loading={resendAcknowledgementLoading}
						onClick={handleResendAcknowledgement}
						size="standard"
					>
						Resend Acknowledgement
					</Button>
				</div>
			);
		}
		return null;
	};

	const renderAcknowledgeTermsButton = () => {
		const loanExists = loanData && loanData.id;
		const isOobaAcknowledgementRequired: Boolean =
			appData?.application.status === AppStatus.DocsReady &&
			!appData.application.acknowledgedBy;
		if (
			viewRolePermissions.get(RolePermissions.ShowAcknowledgeTermsButton)?.has(role!) &&
			loanExists &&
			isOobaAcknowledgementRequired
		) {
			const queryStringVars = {
				dealerId: store?.publicId,
				applicationId: appData?.application.id,
			};

			return (
				<div className="button-wrapper">
					<Button
						className="button"
						onClick={() => {
							window.location.href = `${
								process.env.REACT_APP_LOANS_UI_URL
							}?${qs.stringify(queryStringVars)}`;
						}}
						size="standard"
					>
						Acknowledge Terms
					</Button>
				</div>
			);
		}
		return null;
	};

	const renderUpdatePrincipalButton = () => {
		const allowedStatuses: AppStatus[] = [
			AppStatus.Approved,
			AppStatus.DocsReady,
			AppStatus.Accepted,
		];
		const loanExists = loanData && loanData.id;
		if (
			viewRolePermissions.get(RolePermissions.ShowUpdatePrincipalButton)?.has(role!) &&
			includes(allowedStatuses, appData?.application.status) &&
			loanExists
		) {
			return (
				<div className="button-wrapper">
					<Button
						className="button"
						onClick={() => {
							setShowPrincipalUpdateModal(true);
						}}
						size="standard"
					>
						Manage Purchase Amount
					</Button>
				</div>
			);
		}
		return null;
	};

	const renderHomeOwnershipButton = () => {
		const allowedStatuses: AppStatus[] = [AppStatus.DataRequested];
		if (
			viewRolePermissions.get(RolePermissions.ShowHomeOwnershipButton)?.has(role!) &&
			includes(allowedStatuses, appData?.application.status) &&
			appData?.application.homeOwnership === 'pending'
		) {
			return (
				<div className="button-wrapper">
					<Button
						className="button"
						onClick={() => {
							setShowHomeOwnershipModal(true);
						}}
						size="standard"
					>
						Record Homeownership Verification
					</Button>
				</div>
			);
		}
		return null;
	};

	const renderMarkAsFundedButton = () => {
		const allowedStatuses: AppStatus[] = [AppStatus.Delivered];

		if (
			!(
				viewRolePermissions.get(RolePermissions.ShowMarkAsFundedButton)?.has(role!) &&
				includes(allowedStatuses, appData?.application.status)
			)
		) {
			return null;
		}

		const handleMarkAsFunded = async () => {
			setMarkAsFundedLoading(true);
			const error = await updateApplicationToFunded(appData!.application.id);

			if (error) {
				setError(error);
			}
			setMarkAsFundedLoading(false);
		};

		return (
			<div className="button-wrapper">
				<Button
					className="buttonContainer"
					loading={markAsFundedLoading}
					onClick={handleMarkAsFunded}
					size="standard"
				>
					Mark as Funded
				</Button>
			</div>
		);
	};

	const shouldRenderOverview = (): boolean => {
		const allowedStatuses = [
			AppStatus.Approved,
			AppStatus.Accepted,
			AppStatus.DocsReady,
			AppStatus.Delivered,
			AppStatus.Funded,
			AppStatus.Expired,
		];
		return allowedStatuses.includes(appData!.application.status);
	};

	const isEcommerce = (): boolean => {
		return appData?.application.label === AppLabel.ECommerce;
	};

	const renderUpdatePrincipalModal = () => {
		const allowedStatuses = [AppStatus.Approved, AppStatus.DocsReady, AppStatus.Accepted];
		if (
			viewRolePermissions.get(RolePermissions.ShowUpdatePurchaseAmount)?.has(role!) &&
			allowedStatuses.indexOf(appData!.application.status) > -1 &&
			appData?.application.loanId
		) {
			const selectedProduct = appData!.offeredProducts?.find(
				(x) => x.productId === appData?.application.selectedProductId,
			);
			return (
				<Modal
					isOpen={showPrincipalUpdateModal}
					setIsOpen={setShowPrincipalUpdateModal}
					customButtons
				>
					<UpdatePrincipalModal
						loanId={loanData!.id}
						loanAmount={loanData!.loanAmount}
						applicationId={appData!.application.id}
						dealerId={appData!.application.dealerId.toString()}
						maximumAmount={selectedProduct!.lineAmount}
						minimumAmount={selectedProduct!.minimumSpend}
						onClose={() => {
							setShowPrincipalUpdateModal(false);
						}}
					/>
				</Modal>
			);
		}
		return null;
	};
	const renderHomeOwnershipModal = () => {
		const allowedStatuses: AppStatus[] = [AppStatus.DataRequested];
		if (
			viewRolePermissions.get(RolePermissions.ShowHomeOwnershipButton)?.has(role!) &&
			includes(allowedStatuses, appData?.application.status)
		) {
			return (
				<Modal
					isOpen={showHomeOwnershipModal}
					setIsOpen={setShowHomeOwnershipModal}
					customButtons
				>
					<HomeOwnershipModal
						storeName={store!.name}
						dealerId={store!.dealerId}
						applicationId={appData!.application.id}
						onClose={() => {
							setShowHomeOwnershipModal(false);
						}}
					/>
				</Modal>
			);
		}
		return null;
	};

	return appData && store ? (
		<div className="loan-details">
			<ErrorModal error={error} setOpened={() => setError(null)} />
			<div>
				<LoanStatus canEditLoanStatus={canEditLoanStatus()} />
				{isEcommerce() ? (
					<div className="ecommerce-banner">
						<i className="icon-alert-triangle"></i>
						<span>
							This is an eCommerce application. Not all actions are supported.
						</span>
					</div>
				) : null}
				<section className="buttons my-3">
					{renderLoanProButton()}
					{renderResendAcknowledgementButton()}
					{renderAcknowledgeTermsButton()}
					{renderResumeButton()}
					{renderUpdatePrincipalButton()}
					{renderMarkAsFundedButton()}
					{renderHomeOwnershipButton()}
				</section>
				<section className="container-fluid">
					<div className="row">
						{/* Tabs Here */}
						{shouldRenderOverview() ? (
							<section className="col-sm-8 col-lg-9 mb-4 mb-sm-0">
								<ul className="nav nav-tabs loan-details-tabs" role="tablist">
									<li className="nav-item">
										<a
											className="nav-link active loan-details-tab loan-details-strong-label"
											href="#overview"
											role="tab"
										>
											Overview
										</a>
									</li>
								</ul>

								{/* Tab panes */}
								<div className="loan-details-tab-content">
									<div
										role="tabpanel"
										className="tab-pane fade show active"
										id="overview"
									>
										<AppOverview />
									</div>
								</div>
							</section>
						) : (
							<div className="col-sm-8 col-lg-9 mb-4 mb-sm-0" />
						)}
						<section className="col-sm-4 col-lg-3 pull-right">
							<ApplicationDetailsSidebar />
						</section>
					</div>
				</section>
			</div>
			{renderUpdatePrincipalModal()}
			{renderHomeOwnershipModal()}
		</div>
	) : null;
};
export {LoanDetails};
