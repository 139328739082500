import React, {FC} from 'react';
import {FadeLoader} from 'react-spinners';

import './loading-spinner.scss';

interface P {
	color: string;
	height: string;
	width: string;
}

const LoadingSpinner: FC<P> = ({color, height, width}) => {
	return (
		<div className="loading-spinner">
			<FadeLoader color={color} height={height} width={width} radius="10px" margin={15} />
		</div>
	);
};

export default LoadingSpinner;
