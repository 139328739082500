import React from 'react';
import {Column, useTable} from 'react-table';
import './table.scss';

interface TableProps {
	columns: Column<any>[];
	data: any[];
	enableRowHeaders?: boolean;
}

export const Table: React.FC<TableProps> = ({columns, data, enableRowHeaders = false}) => {
	const {getTableProps, getTableBodyProps, prepareRow, rows, flatHeaders} = useTable({
		columns,
		data,
	});

	return (
		<div>
			<table className="table-container" {...getTableProps()}>
				<thead className="table-header">
					<tr>
						{flatHeaders.map((column) => (
							<th {...column.getHeaderProps()}>{column.render('Header')}</th>
						))}
					</tr>
				</thead>
				<tbody {...getTableBodyProps()}>
					{rows.map((row) => {
						prepareRow(row);
						return (
							<tr {...row.getRowProps()}>
								{row.cells.map((cell) => (
									<td {...cell.getCellProps()}>{cell.render('Cell')}</td>
								))}
							</tr>
						);
					})}
				</tbody>
			</table>
			<div className="lists-container">
				{rows.map((row, i) => (
					<div className="table-list-item" key={i}>
						{flatHeaders.map((header, i) =>
							enableRowHeaders && i === 0 ? (
								<header className="list-header" key={i}>
									<h5>{row.cells[i].value}</h5>
									<p>{header.render('Header')}</p>
								</header>
							) : (
								<dl key={i}>
									<dt>{header.render('Header')}</dt>
									<dd>{row.cells[i].value}</dd>
								</dl>
							),
						)}
					</div>
				))}
			</div>
		</div>
	);
};
