import React, {useState, useEffect} from 'react';
import {AppStatus} from '../../types/applications';
import {Button} from '../button';
import './loan-status.scss';
import {ProgressBar} from '../progress-bar';
import {Badge} from '../badge';
import {updateApplicationToExpired} from '../../apis';
import {ErrorModal} from '../modal/error-modal';
import {Errors} from '../../types';
import {typedState} from '../../store';
import {Flag} from '../flag';
import {track} from '../../utils/metrics/segment';

export interface IProps {
	canEditLoanStatus: boolean | undefined;
}

export const LoanStatus: React.FC<IProps> = ({canEditLoanStatus}) => {
	const {
		application: {id, firstName, lastName, selectedProductId, status},
		statusHistory,
		offeredProducts,
	} = typedState((state) => state.applications.application!);
	const [error, setError] = useState<Errors | null>(null);
	const [badgeStatus, setBadgeStatus] = useState<{
		selectedStatus: AppStatus;
		reset?: () => void;
	}>({
		selectedStatus: status,
		reset() {},
	});
	const onBadgeChange = (tempBadgeStatus: AppStatus, resetBadgeFn?: () => void) => {
		setBadgeStatus({
			selectedStatus: tempBadgeStatus,
			reset: resetBadgeFn,
		});
	};
	const onBadgeSave = async () => {
		track('Application Manually Expired', {
			additionalProperties: {
				applicationId: id,
				apptype: 'loan',
			},
		});

		const error = await updateApplicationToExpired(id);

		if (error) {
			setError(error);
		}
	};

	// Update selected badge status if appliction status is updated
	useEffect(() => {
		setBadgeStatus({selectedStatus: status});
	}, [status]);

	return (
		<div className="loan-status-container py-4 px-5">
			<ErrorModal error={error} setOpened={() => setError(null)} />
			<header className="d-flex flex-wrap-reverse flex-sm-nowrap">
				<section className="mr-sm-5 word-break-break">
					<form id="nameForm" className="d-flex align-items-start">
						<h1 className="me-1 applicant-name">{`${firstName} ${lastName}`}</h1>
						<Flag>Loan</Flag>
					</form>
					<h3 className="loan-id">Loan {id}</h3>
				</section>
				<section>
					<Badge status={status} onChange={onBadgeChange} editable={canEditLoanStatus} />
					{badgeStatus.selectedStatus !== status && (
						<div className="loan-status-container px-2 mt-2">
							<div className="status-buttons-row row">
								<div className="status-buttons">
									<Button onClick={badgeStatus.reset} className="btn-cancel">
										Cancel
									</Button>
								</div>
								<div className="status-buttons">
									<Button onClick={onBadgeSave} className="btn-save">
										<div className="loan-status-btn-content-container">
											<i className="icon-save me-2" />
											<div>Save</div>
										</div>
									</Button>
								</div>
							</div>
						</div>
					)}
				</section>
			</header>
			<ProgressBar
				status={status}
				offeredProducts={offeredProducts}
				selectedProductId={selectedProductId}
				statusHistory={statusHistory}
			/>
		</div>
	);
};
