import {store} from '../../store';

declare global {
	interface Window {
		analytics: any;
	}
}

interface Payload {
	[index: string]: string | number | undefined | null;
}

const track = (
	message: string,
	props: {
		additionalProperties?: {
			[index: string]: string | number | undefined;
		};
	},
) => {
	const {additionalProperties} = props;
	const globalStore = store.getState();

	const payload: Payload = {
		dealerID: globalStore.stores.store?.dealerId,
		storeID: globalStore.stores.store?.dealerId,
		storeName: globalStore.stores.store?.name,
		vertical:
			globalStore.stores.store?.vertical && globalStore.stores.store?.vertical.toUpperCase(),
		...additionalProperties,
	};

	window.analytics.track(message, payload);
};

// TODO pull dealer info
const identify = (dealer: any) => {
	window.analytics.identify(dealer.id, {
		dealer,
	});
};

export {track, identify};
