import React, {useEffect} from 'react';
import './App.scss';
import {Routes} from './pages';
import Header from './components/header';
import Footer from './components/footer';
import {typedActions, typedState} from './store';
import LoadingSpinner from './components/loading-spinner';

function App() {
	// global state
	const auth = typedState((state) => state.auth);
	const loading = typedState((state) => state.loading);

	// global actions
	const {authenticate} = typedActions((actions) => actions.auth);

	useEffect(() => {
		authenticate();
	}, []);

	// initial load
	if (auth.user === undefined) return null;
	// invalid/missing JWT token
	else if (auth.user === null) window.location.replace(`https://${auth.dealerPortalUrl}`);

	return (
		<div style={{height: '100%'}}>
			<Header />
			{loading.isLoading && <LoadingSpinner color="#0d4066" width="10px" height="30px" />}
			<div className="main">
				<Routes />
			</div>
			<Footer />
		</div>
	);
}

export default App;
