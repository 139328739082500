import React, {useState} from 'react';
import NumberFormat, {NumberFormatProps, NumberFormatValues} from 'react-number-format';
import './field.scss';

interface P {
	className?: string;
	label: string;
	value?: any;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onValueChange?: (values: NumberFormatValues) => void;
	disabled?: boolean;
	format?: 'currency' | 'percentage';
	error?: string;
}

export const Field: React.FC<P> = ({
	label,
	value,
	onChange,
	onValueChange,
	disabled = false,
	className,
	format,
	error,
}) => {
	const [focus, setFocus] = useState(false);

	const currencyOptions: NumberFormatProps<any> = {
		thousandSeparator: true,
		fixedDecimalScale: true,
		decimalScale: 2,
		prefix: '$ ',
	};

	const percentageOptions: NumberFormatProps<any> = {
		decimalScale: 3,
		suffix: '%',
	};

	const defaultProps = {
		value: value || '',
		onChange,
		disabled,
		onFocus: () => setFocus(true),
		onBlur: () => setFocus(false),
		id: label,
		name: label,
		placeholder: disabled ? 'N/A' : '',
	};

	return (
		<div
			className={`field-container ${focus ? 'focus ' : ''}${
				error ? 'error ' : ''
			}${className}`}
		>
			{format ? (
				<NumberFormat
					{...defaultProps}
					onValueChange={onValueChange}
					{...(format === 'currency' && currencyOptions)}
					{...(format === 'percentage' && percentageOptions)}
				/>
			) : (
				<input {...defaultProps} />
			)}
			{error && (
				<label className="error" htmlFor={label}>
					{error}
				</label>
			)}
			<label className={value || disabled ? 'isExpanded' : ''} htmlFor={label}>
				{label}
			</label>
		</div>
	);
};
