/*
 Simple cookie reader function which accepts a partial cookie name and returns
 the results in [cookieKey, cookieValue] format. If there are no cookies found that
 match passed partial cookie name the helper returns blank strings.
*/
const getCookie = (partialName: string): [string, string] => {
	const matchedCookie = document.cookie
		.split(`; `)
		.find((cookie) => cookie.includes(partialName));
	if (matchedCookie) return matchedCookie.split('=') as [string, string];
	return ['', ''];
};

/*
 Simple cookie reader function which accepts a full cookie name and returns
 the results in [cookieKey, cookieValue] format. If there are no cookies found that
 match passed full cookie name the helper returns blank strings.
*/
const getCookieByFullName = (fullName: string): [string, string] => {
	const matchedCookie = document.cookie
		.split(`; `)
		.find((cookie) => cookie.split('=')[0] === fullName);
	if (matchedCookie) return matchedCookie.split('=') as [string, string];
	return ['', ''];
};

export {getCookie, getCookieByFullName};
