import React, {useState} from 'react';
import {Button} from '../button';
import './homeownership-modal.scss';
import {Errors} from '../../types';
import {updateApplicationHomeOwnership} from "../../apis/apis";
import {HomeOwnershipValues} from "../../types/applications";
import {ErrorModal} from "../modal/error-modal";
//@ts-ignore
import {ReactComponent as CloseIcon} from '../../assets/icons/x-close.svg';
import {track} from '../../utils';

export interface HomeOwnershipModalProps {
	storeName: string;
	dealerId: number;
	applicationId: number;
	onClose?: () => void;
}

const HomeOwnershipModal: React.FC<HomeOwnershipModalProps> =
	({
		 dealerId,
		 storeName,
		 applicationId,
		 onClose,
	 }) => {
		const [error, setError] = useState<Errors | null>(null);
		const [isUpdating, setIsUpdating] = useState(false);
		const [updateResponseSuccessful, setUpdateResponseSuccess] = useState<boolean | null>(null);

		const renderUnsuccessfulMessage = (isSuccessful: boolean | null) => {
			if (isSuccessful === false || null) {
				const text = 'An error occured: The home ownership could not be updated';
				return (
					<div className="row mt-3">
						<div className="col-12 homeownership-error-text">{text}</div>
					</div>
				);
			}
			return null;
		};

		const onSubmitClick = async (homeOwnership: HomeOwnershipValues) => {
			let segmentDecision: string
			if (homeOwnership === HomeOwnershipValues.Verified) {
				segmentDecision = "Yes"
			} else {
				segmentDecision = "No"
			}
			track('Dynamic App - Homeownership Ops-Decisioned', {
				additionalProperties: {
					storeName: storeName,
					loanApplicationNumber: applicationId,
					decision: segmentDecision,
				}
			});

			const error = await updateApplicationHomeOwnership(applicationId, homeOwnership)
			if (error) {
				setError(error);
				setUpdateResponseSuccess(false);
			} else {
				setUpdateResponseSuccess(true);
				if (onClose) {
					onClose();
				}
			}
			setIsUpdating(false);
		};

		return (
			<div className="homeownership-modal">
				<ErrorModal error={error} setOpened={() => setError(null)}/>
				{renderUnsuccessfulMessage(updateResponseSuccessful)}
				<div className="homeownership-close-modal-button">
					<button type="button" className="close-modal-button" onClick={onClose}>
						<CloseIcon title="Close Search Results Icon" role="img"/>
					</button>
				</div>
				<div className="homeownership-modal-content">
					<div className="homeownership-text">
						Were you able to verify that the applicant is authorized to perform work at the home address?
					</div>
					<div className="homeownership-button-container">
						<div className="homeownership-yes-modal-button d-inline-block me-3">
							<Button
								onClick={() => onSubmitClick(HomeOwnershipValues.Verified)}
								size="standard"
								disabled={isUpdating}
							>
								Yes
							</Button>
						</div>
						<div className="homeownership-no-modal-button d-inline-block me-3">
							<Button
								onClick={() => onSubmitClick(HomeOwnershipValues.Unverified)}
								size="standard"
								disabled={isUpdating}
							>
								No
							</Button>
						</div>
					</div>
				</div>
			</div>
		);
	};


export {HomeOwnershipModal};
