import React from 'react';
import {Errors} from '../../types';
import {Modal} from './modal';

interface P {
	error: Errors | null;
	setOpened: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ErrorModal: React.FC<P> = ({error, setOpened}) => {
	return (
		<Modal isOpen={!!error} setIsOpen={setOpened}>
			{error && 'Something went wrong, please try again.'}
		</Modal>
	);
};
