import React from 'react';
import ReactModal from 'react-modal';
import {Button} from '../button';
import './modal.scss';

interface ModalProps {
	isOpen: boolean;
	customButtons?: boolean;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Modal: React.FC<ModalProps> = ({children, customButtons, isOpen, setIsOpen}) => {
	return (
		<ReactModal isOpen={isOpen} className={'modal'}>
			<div>
				<div className={'modal-content'}>{children}</div>
				{customButtons !== true && (
					<Button onClick={() => setIsOpen(!isOpen)}>Close</Button>
				)}
			</div>
		</ReactModal>
	);
};
