import {AxiosError} from 'axios';
import {Action, action, thunk, Thunk} from 'easy-peasy';
import {Errors} from '../../types';
import {Loan} from '../../types/loans';
import {store} from '../index';

export interface LoansModel {
	loan?: Loan;

	setLoan: Action<LoansModel, Loan>;

	fetchLoan: Thunk<LoansModel, number>;
	updateLoanPrincipalAmount: Thunk<
		LoansModel,
		{
			applicationId: number;
			dealerId: string;
			principal: string;
			loanId: number;
		}
	>;
}

const fetchLoan = thunk<LoansModel, number>(async (actions, loanId, helpers) => {
	const httpClient = store.getState().auth.httpClient;

	await httpClient!
		.get(`/loans/${loanId}`)
		.then(async (response) => {
			const {data: loan}: {data: Loan} = response;
			actions.setLoan(loan);
		})
		.catch((e: AxiosError) => {
			console.error('unable to fetch loan', e.response?.data);
			switch (e.response?.status) {
				case 400:
					throw new Error(Errors.InvalidRequestData);
				case 404:
					throw new Error(Errors.LoanNotFound);
				case 403:
					throw new Error(Errors.Unauthorized);
				default:
					throw new Error(Errors.GeneralError);
			}
		});
});

const updateLoanPrincipalAmount = thunk<
	LoansModel,
	{applicationId: number; dealerId: string; principal: string; loanId: number}
>(async (actions, {applicationId, loanId, dealerId, principal}, helpers) => {
	const httpClient = store.getState().auth.httpClient;

	await httpClient!
		.put(`/loans/${loanId}/principal-amount`, {
			applicationId,
			dealerId,
			principal,
		})
		.then(async () => {
			await actions.fetchLoan(loanId).catch((e: Error) => {
				throw e;
			});
		})
		.catch((e: AxiosError) => {
			console.error('unable to update loan principal amount', e.response?.data);
			switch (e.response?.status) {
				case 400:
					throw new Error(Errors.InvalidRequestData);
				case 404:
					throw new Error(Errors.LoanNotFound);
				case 403:
					throw new Error(Errors.Unauthorized);
				default:
					throw new Error(Errors.GeneralError);
			}
		});
});

export const initLoansModel = (): LoansModel => ({
	loan: undefined,

	// actions
	setLoan: action((state, loan) => {
		state.loan = loan;
	}),

	// thunks
	fetchLoan,
	updateLoanPrincipalAmount,
});
