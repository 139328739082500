import {createStore, createTypedHooks} from 'easy-peasy';
// import {gimme} from 'gimme-state';
import {ApplicationsModel, initApplicationsModel} from './models/applications';
import {AuthModel, initAuthModel} from './models/auth';
import {initLoadingModel, LoadingModel} from './models/loading';
import {initLoansModel, LoansModel} from './models/loans';
import {initStoresModel, StoresModel} from './models/stores';

export interface Modules {
	auth: AuthModel;
	applications: ApplicationsModel;
	stores: StoresModel;
	loans: LoansModel;
	loading: LoadingModel;
}

const modules: Modules = {
	auth: initAuthModel(),
	applications: initApplicationsModel(),
	stores: initStoresModel(),
	loans: initLoansModel(),
	loading: initLoadingModel(),
};

const typedHooks = createTypedHooks<Modules>();

const typedActions = typedHooks.useStoreActions;
const typedState = typedHooks.useStoreState;
const store = createStore(modules, {
	// middleware: [gimme], // uncomment this line for easy-peasy logs
});

export {typedActions, typedState, store};
