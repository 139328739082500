import React, {useRef, useEffect, useMemo} from 'react';
import './badge.scss';
import {AppStatus} from '../../types/applications';
import Select, {SingleValue} from 'react-select';
import {snakeCaseToSpaces} from '../../utils';

export interface StatusOption {
	key: string;
	value: AppStatus;
	label: string;
}

interface P {
	status: AppStatus;
	onChange?: (status: AppStatus, resetBadge?: () => void) => void;
	editable?: boolean;
}

const getAvailableStatusOptions = (status: AppStatus) => {
	// currently, the status dropdown only supports moving statuses to expired
	const statusOptions: StatusOption[] = [
		{key: status, value: status, label: snakeCaseToSpaces(status)},
	];
	if (status !== AppStatus.Expired) {
		statusOptions.push({
			key: AppStatus.Expired,
			value: AppStatus.Expired,
			label: snakeCaseToSpaces(AppStatus.Expired),
		});
	}
	return statusOptions;
};

const dropDownActiveStyles = {
	indicatorSeparator: () => ({
		// none of react-select's styles are passed to <Control />
		display: 'none',
	}),
};

const dropDownInactiveStyles = {
	indicatorSeparator: () => ({
		display: 'none',
	}),
	dropdownIndicator: () => ({
		display: 'none',
	}),
};

export const Badge: React.FC<P> = ({status, onChange, editable}) => {
	const selectRef = useRef(null);
	const options = useMemo(() => getAvailableStatusOptions(status), [status]);

	useEffect(() => {
		if (
			selectRef &&
			// @ts-ignore line
			selectRef.current?.state.selectValue.length &&
			// @ts-ignore line
			status !== selectRef.current?.state.selectValue[0].value
		) {
			// @ts-ignore line
			selectRef.current!.setValue(
				{
					key: status,
					value: status,
					label: snakeCaseToSpaces(status),
				},
				'select-option',
			);
		}
	}, [status]);

	const resetStatus = () => {
		if (selectRef && selectRef.current) {
			// @ts-ignore line
			selectRef.current.setValue(options[0], 'select-option');
		}
	};
	const onSelectChange = (option: SingleValue<StatusOption>) => {
		const value = option ? option.value : status;
		if (typeof onChange === 'function') onChange(value, resetStatus);
	};

	return (
		<Select
			ref={selectRef}
			className={`badge badge-${status}`}
			classNamePrefix="koalafi-badge"
			defaultValue={options[0]}
			isSearchable={false}
			options={options}
			onChange={onSelectChange}
			isDisabled={editable !== undefined && !editable}
			styles={
				editable !== undefined && !editable ? dropDownInactiveStyles : dropDownActiveStyles
			}
		/>
	);
};
