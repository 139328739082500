import React, {useEffect, useState} from 'react';
import './customer-search-results-table.scss';
import {SearchCustomerAppsResult, SearchResult} from '../../types/search-apps';
import {LEASE_APPLICATION_STATUS} from '../../types/constants';
import {typedState} from '../../store';
import {RolePermissions, viewRolePermissions} from '../../types';
import {useNavigate} from 'react-router-dom';
import {camelCaseToSpaces, removeSpaces} from '../../utils';

interface CustomerSearchResultsTableProps {
	searchCustomerAppsResult: SearchCustomerAppsResult | undefined;
}

const CustomerSearchResultsTable: React.FC<CustomerSearchResultsTableProps> = ({
	searchCustomerAppsResult,
}) => {
	const auth = typedState((state) => state.auth);
	const navigate = useNavigate();
	const [searchResults, setSearchResults] = useState<SearchResult[] | null>(null);

	useEffect(() => {
		const results = searchCustomerAppsResult?.SearchResult.length
			? searchCustomerAppsResult?.SearchResult
			: null;
		setSearchResults(results);
	}, [searchCustomerAppsResult]);

	const onClickAppResult = (appResult: SearchResult): void => {
		let url = '';
		if (appResult.IsLoan) {
			url = `/DealerCabinet/LoanDetails?applicationId=${appResult.ApplicationPublicId}`;
			navigate(url);
		} else if (
			appResult.ApplicationStatusResultValue === LEASE_APPLICATION_STATUS.FUNDED &&
			appResult.SentToBackend &&
			viewRolePermissions
				.get(RolePermissions.ShowFindCustomerCanSeeLinkToRain)
				?.has(auth.user!.role)
		) {
			url = `https://${auth.rainUrl}/LeaseDetails/${appResult.ApplicationNumber}`;
		} else {
			url = `https://${auth.dealerPortalUrl}/DealerCabinet/ApplicationDetails?publicId=${appResult.ApplicationPublicId}`;
		}

		window.location.assign(url);
	};

	return (
		<div className="customer-search-results-table">
			{searchResults ? (
				<table>
					<thead>
						<tr>
							<th>Customer</th>
							<th>Updated</th>
							<th>Amount</th>
							<th>Status</th>
						</tr>
					</thead>
					<tbody>
						{searchResults.map((result) => (
							<tr
								onClick={() => onClickAppResult(result)}
								key={result.ApplicationNumber}
							>
								<td data-label="Customer">
									<span>
										{result.FullName}
										<small>
											{result.IsLoan ? 'Loan' : 'Lease'}{' '}
											{result.ApplicationNumber}
										</small>
									</span>
								</td>
								<td data-label="Updated">{result.LastUpdated}</td>
								<td data-label="Amount">{result.Amount}</td>
								<td data-label="Status">
									<span
										className={`app-status app-status-btn-${removeSpaces(
											result.AppStatusName,
										)}`}
									>
										{camelCaseToSpaces(result.AppStatusName)}
									</span>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			) : (
				<div className="no-results">
					<p>We're sorry, but your search returned no results.</p>
					<p>
						Please try again using the customer's first and/or last name or search by
						application number.
					</p>
				</div>
			)}
		</div>
	);
};

export default CustomerSearchResultsTable;
