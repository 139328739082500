import {useEffect, useState} from 'react';
import {typedActions, typedState} from '../store';
import {Errors} from '../types';

/**
 * Retrieves existing application (and maybe loan) data,
 * saving it to application Redux
 */
export default function useAppInfo(applicationId: string): [boolean, Errors | null] {
	// Local State
	const [loading, setLoading] = useState(!!applicationId);
	const [error, setError] = useState<Errors | null>(null);

	// Global State
	const appData = typedState((state) => state.applications.application);

	// Global Actions
	const {fetchApplication} = typedActions((actions) => actions.applications);
	const {fetchLoan} = typedActions((actions) => actions.loans);

	// fetch App data
	useEffect(() => {
		if (!!applicationId) {
			setLoading(true);
			(async () => {
				await fetchApplication(+applicationId).catch((e: Error) => {
					setError(e.message as Errors);
					setLoading(false);
				});
			})();
		}
	}, [applicationId, setLoading]);

	// fetch Loan data
	useEffect(() => {
		// wait for application data to load
		if (loading && appData) {
			// only fetch loan data if app has an attached loan_id
			if (appData?.application.loanId) {
				(async () => {
					await fetchLoan(appData.application.loanId)
						.catch((e: Error) => {
							setError(e.message as Errors);
						})
						.finally(() => {
							setLoading(false);
						});
				})();
			} else {
				setLoading(false);
			}
		}
	}, [loading, appData]);
	return [loading, error];
}
