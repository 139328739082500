import React from 'react';
import {formatDateOnly, formatPhoneNumber, isString} from '../../utils/format/format';
import './app-details-sidebar.scss';
import {typedState} from '../../store';

export const ApplicationDetailsSidebar: React.FC = () => {
	const {
		application: {dateOfBirth, address, city, state, zip, mobilePhone, email},
	} = typedState((state) => state.applications.application!);
	const {store} = typedState((state) => state.stores);
	const {dealerPortalUrl} = typedState((state) => state.auth);

	const detailRow = (
		iconClass: string,
		label: string | React.ReactFragment,
		send?: 'tel' | 'mailto',
	) => {
		return (
			<div className="detail">
				<i className={iconClass} />

				{send ? (
					<a href={`${send}:${label}`}>
						{isString(label) && send === 'tel' ? formatPhoneNumber(label) : label}
					</a>
				) : (
					<div className="detail-label">{label || 'N/A'}</div>
				)}
			</div>
		);
	};

	return (
		<div className="detail-container">
			<h4>Contact Details</h4>
			<section className="detail-section">
				<section>
					{detailRow('icon-calendar', formatDateOnly(dateOfBirth))}
					{detailRow(
						'icon-home',
						<>
							<div>{address}</div>
							<div>{`${city}, ${state} ${zip}`}</div>
						</>,
					)}
				</section>
				<div>
					<hr />
				</div>
				<section>
					{detailRow('icon-phone', mobilePhone, 'tel')}
					{detailRow('icon-mail', email, 'mailto')}
				</section>
			</section>

			<h4>Store Details </h4>
			<section className="detail-section">
				<div className="detail-store-name">
					<div>
						{store?.dealerId && (
							<a
								href={`https://${dealerPortalUrl}/StoresList/StoreDetails?publicId=${store.publicId}`}
								className="color-indigo"
							>
								{store?.name}
							</a>
						)}
					</div>
				</div>
				<div>
					<hr />
				</div>
				<section>
					{detailRow('icon-phone', store!.phoneNumber, 'tel')}
					{detailRow('icon-mail', store!.email, 'mailto')}
				</section>
			</section>
		</div>
	);
};
