import {useEffect, useState} from 'react';
import {typedActions, typedState} from '../store';
import {Errors} from '../types';

/**
 * Custom hook that retrieves data for a given dealer and saves it to Redux
 */
export default function useDealerInfo(): [boolean, Errors | null] {
	const appData = typedState((state) => state.applications.application);
	const {store} = typedState((state) => state.stores);

	const {fetchStore} = typedActions((actions) => actions.stores);

	const [error, setError] = useState<Errors | null>(null);
	const [loading, setLoading] = useState<boolean>(true);

	// fetch store data
	useEffect(() => {
		// run if new app is fetched from SearchCustomerApps
		if (appData && store && appData.application.dealerId !== store!.dealerId) {
			setLoading(true);
		}
		// wait for app data to be fetched
		if (loading && appData) {
			(async () => {
				await fetchStore(appData?.application.dealerId)
					.catch((e: Error) => setError(e.message as Errors))
					.finally(() => setLoading(false));
			})();
		}
	}, [loading, appData]);

	return [loading, error];
}
