import React from 'react';
import './toggle.scss';

interface ToggleProps {
	checked: boolean;
	handleChange: (event: React.SyntheticEvent<HTMLDivElement>) => void;
	name: string;
	disabled?: boolean;
}

export const Toggle: React.FC<ToggleProps> = ({checked, handleChange, name, disabled}) => {
	const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.keyCode === 32 && !disabled) {
			handleChange(event);
		}
	};

	return (
		<div
			className="toggle-container"
			onClick={!disabled ? handleChange : undefined}
			role="switch"
			aria-checked={checked}
			aria-readonly={disabled}
			tabIndex={0}
			onKeyDown={handleKeyDown}
			id={name}
		>
			<label
				className={`toggle-label toggle-label-${
					disabled ? 'disabled' : checked ? 'checked' : ''
				}`}
				htmlFor={name}
			>
				<div className="toggle" />
				{checked && <span>Yes</span>}
				{!checked && <span>No</span>}
			</label>
		</div>
	);
};
