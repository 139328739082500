import React, {useEffect, useState} from 'react';
import {Route, Routes, useLocation} from 'react-router-dom';
import {LoanDetails} from '../loan-details';
import {useAppInfo, useDealerInfo} from '../../hooks';
import qs from 'query-string';
import {Queries} from '../../types/navigation';
import {ErrorModal} from '../../components/modal/error-modal';
import {typedState} from '../../store';
import {Errors} from '../../types';

const Router = () => {
	// Hooks
	const location = useLocation();
	const queries = qs.parse(location.search) as Queries;

	const [appLoading, appLoadError] = useAppInfo(queries.applicationId);
	const [dealerLoading, dealerLoadError] = useDealerInfo();

	// Local State
	const [initialLoad, setInitialLoad] = useState<boolean>(true);

	// Global State
	const auth = typedState((state) => state.auth);

	// wait for AppInfo and DealerInfo to load
	useEffect(() => {
		if (initialLoad && !dealerLoading && !appLoading) setInitialLoad(false);
	}, [dealerLoading, appLoading, initialLoad]);

	if (appLoadError || dealerLoadError) {
		// Dealer portal returns 400 in case the user role does not have permissions to view the page.
		// Redirect back to RAPID if unauthorized.
		if (
			dealerLoadError === Errors.InvalidRequestData ||
			appLoadError === Errors.Unauthorized ||
			dealerLoadError === Errors.Unauthorized
		) {
			window.location.replace(`https://${auth.dealerPortalUrl}`);
			return null;
		}
		return (
			<ErrorModal
				error={appLoadError ? appLoadError : dealerLoadError}
				setOpened={() => window.location.replace(`https://${auth.dealerPortalUrl}`)}
			/>
		);
	}

	if (initialLoad) return null;

	return (
		<Routes>
			<Route element={<ScrollToTop />} />
			<Route path={'/'} element={<LoanDetails />}>
				<Route path={'DealerCabinet'}>
					<Route path={'LoanDetails'} element={<LoanDetails />} />
				</Route>
			</Route>
		</Routes>
	);
};

/**
 * A small functional component with the sole
 * purpose of scrolling to the top
 */
const ScrollToTop = () => {
	window.scrollTo(0, 0);
	return null;
};

export default Router;
