import {Action, action, thunk, Thunk} from 'easy-peasy';

export interface LoadingModel {
	isLoading?: boolean;

	setIsLoading: Action<LoadingModel, boolean>;

	setLoadingStatus: Thunk<LoadingModel, boolean>;
}

const setLoadingStatus = thunk<LoadingModel, boolean>((actions, isLoading, helpers) => {
	actions.setIsLoading(isLoading);
});

export const initLoadingModel = (): LoadingModel => ({
	isLoading: false,
	// actions
	setIsLoading: action((state, loadingState) => {
		state.isLoading = loadingState;
	}),

	// thunks
	setLoadingStatus,
});
