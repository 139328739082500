import React, {useState} from 'react';
import {NumberFormatValues} from 'react-number-format';
import {Field} from '../inputs/field';
import {Button} from '../button';
import './update-principal-modal.scss';
import {updateLoanPrincipalAmount} from '../../apis';
import {ErrorModal} from '../modal/error-modal';
import {Errors} from '../../types';

export interface UpdatePrincipalModalProps {
	loanId: number;
	loanAmount: string;
	maximumAmount: number;
	minimumAmount: number;
	applicationId: number;
	dealerId: string;
	onClose?: () => void;
}

const UpdatePrincipalModal: React.FC<UpdatePrincipalModalProps> = ({
	loanId,
	loanAmount,
	maximumAmount,
	minimumAmount,
	applicationId,
	dealerId,
	onClose,
}) => {
	const [newAmount, setNewAmount] = useState(loanAmount);
	const [errorMessage, setErrorMessage] = useState('');
	const [error, setError] = useState<Errors | null>(null);
	const [isUpdating, setIsUpdating] = useState(false);
	const [amountHasChanged, setAmountHasChanged] = useState(false);
	const [updateResponseSuccessful, setUpdateResponseSuccess] = useState<boolean | null>(null);

	const renderUnsuccessfulMessage = (isSuccessful: boolean | null) => {
		if (isSuccessful === false || null) {
			const text = 'An error occured: The principal amount could not be updated';

			return (
				<div className="row mt-3">
					<div className="col-12 error-text">{text}</div>
				</div>
			);
		}
		return null;
	};

	const onPrincipalAmountChange = (values: NumberFormatValues) => {
		setNewAmount(values.value);

		setAmountHasChanged(values.value !== loanAmount);

		if (values.floatValue !== undefined) {
			if (values.floatValue < minimumAmount) {
				setErrorMessage(`Amount must be greater than $${minimumAmount}.00`);
			} else if (values.floatValue > maximumAmount) {
				setErrorMessage('Amount must be less than max approved amount');
			} else if (errorMessage !== '') {
				setErrorMessage('');
			}
		}
	};

	const onSubmitClick = async () => {
		setIsUpdating(true);

		const error = await updateLoanPrincipalAmount(loanId, applicationId, dealerId, newAmount);
		if (error) {
			setError(error);
			setUpdateResponseSuccess(false);
		} else {
			setUpdateResponseSuccess(true);
		}
		setIsUpdating(false);
	};

	if (updateResponseSuccessful) {
		const successText = "Your loan's principal amount was updated successfully";
		return (
			<div>
				<div className="row mb-3">
					<h4>Success!</h4>
				</div>
				<div className="row">
					<div className="col-12">{successText}</div>
				</div>
				<div className="row mt-3">
					<div className="col-12">
						<div className="principle-modal-button d-inline-block mr-3">
							<Button onClick={onClose} size="standard" disabled={isUpdating}>
								Return to Loan
							</Button>
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="principle-modal-content">
			<ErrorModal error={error} setOpened={() => setError(null)} />
			<div className="row mb-3">
				<h4 className="loan-principal-header">Update Loan Principal Amount</h4>
			</div>
			<div className="principle-amounts-container">
				<Field
					label="Max Approved Amount"
					value={maximumAmount}
					format="currency"
					disabled
				/>

				<Field
					label="Current Principal Amount"
					value={loanAmount}
					format="currency"
					disabled
				/>

				<Field
					label="New Principal Amount"
					value={newAmount}
					format="currency"
					onValueChange={onPrincipalAmountChange}
					error={errorMessage}
				/>
			</div>
			{renderUnsuccessfulMessage(updateResponseSuccessful)}
			<div className="row mt-3">
				<div className="principle-button-container">
					<div className="principle-modal-button d-inline-block me-3">
						<Button onClick={onClose} size="standard" disabled={isUpdating}>
							Cancel
						</Button>
					</div>
					<div className="principle-modal-button d-inline-block me-3">
						<Button
							onClick={onSubmitClick}
							size="standard"
							disabled={!!errorMessage || isUpdating || !amountHasChanged}
						>
							Update
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export {UpdatePrincipalModal};
