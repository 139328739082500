import axios, {AxiosInstance, AxiosRequestHeaders} from 'axios';
import {v1 as uuidv1} from 'uuid';

export const createAxiosClient = (
	options: {headers: AxiosRequestHeaders} = {headers: {}},
): AxiosInstance => {
	let baseURL = process.env.REACT_APP_MGMT_EDGE_SRV_URL;

	const client = axios.create({baseURL, headers: options.headers});
	client.interceptors.request.use((config) => {
		config.headers = {
			...config.headers,
			'Correlation-ID': uuidv1(),
		};
		return config;
	});
	return client;
};
