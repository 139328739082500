import {format} from 'date-fns';

export function snakeCaseToSpaces(str: string): string {
	return str.replace(/_/g, ' ');
}

export function camelCaseToSpaces(str: string): string {
	return str.replace(/([A-Z])/g, ' $1').toUpperCase();
}

export function removeSpaces(str: string): string {
	return str.replace(/ /g, '').toLowerCase();
}

export function formatToLocalDate(date: string): string {
	return format(new Date(date), 'MM/dd/yyyy');
}

/**
 * formatDateOnly formats date string into MM/DD/YYYY like string
 * @param date
 */
export const formatDateOnly = (date: string): string => {
	const dt = new Date(date);
	const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);
	return format(dtDateOnly, 'MM/dd/yyyy');
};

export function isNumber(x: any): x is number {
	return typeof x === 'number';
}

export function isString(x: any): x is string {
	return typeof x === 'string';
}

export function EnumToArray(e: any): (number | string)[] {
	return Object.keys(e).map((k) => e[k]);
}

export function formatPhoneNumber(str: string): string | null {
	// Filter only numbers from the input
	const cleaned = str.replace(/\D/g, '');

	// Check if the input is of correct length
	const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

	if (match) {
		return `(${match[1]}) ${match[2]}-${match[3]}`;
	}

	return null;
}
