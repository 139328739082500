import {AxiosError} from 'axios';
import {Action, action, thunk, Thunk} from 'easy-peasy';
import {Store} from '../../types/stores';
import {store} from '../index';

export interface StoresModel {
	store?: Store;

	setStore: Action<StoresModel, Store>;

	fetchStore: Thunk<StoresModel, number>;
}

const fetchStore = thunk<StoresModel, number>(async (actions, dealerId, helpers) => {
	const httpClient = store.getState().auth.httpClient;

	await httpClient!
		.request({
			method: 'GET',
			url: `/merchants/stores/${dealerId}`,
			headers: {Accept: 'application/json;v3'},
		})
		.then(async (response) => {
			const {data: store}: {data: Store} = response;
			actions.setStore(store);
		})
		.catch((e: AxiosError) => {
			console.error('unable to get store', e);
			throw e;
		});
});

export const initStoresModel = (): StoresModel => ({
	store: undefined,

	// actions
	setStore: action((state, store) => {
		state.store = store;
	}),

	// thunks
	fetchStore,
});
