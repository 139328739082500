enum Errors {
	UnprocessableAppStatus = 'UNPROCESSABLE_APP_STATUS',
	AppNotFound = 'APP_NOT_FOUND',
	LoanNotFound = 'LOAN_NOT_FOUND',
	DealerNotFound = 'DEALER_NOT_FOUND',
	GeneralError = 'GENERAL_ERROR',
	UnprocessableRole = 'UNPROCESSABLE_ROLE',
	Unauthorized = 'UNAUTHORIZED',
	InvalidRequestData = 'INVALID_REQUEST_DATA',
	OfferedProductNotSelected = 'OFFERED_PRODUCT_NOT_SELECTED',
	UnprocessableLoanAmount = 'UNPROCESSABLE_LOAN_AMOUNT',
}

export {Errors};
