import React from 'react';
import './button.scss';
import Loading from 'react-loading';

export interface ButtonProps {
	onClick?: (event: React.MouseEvent<HTMLElement>) => void | undefined | Promise<void>;
	type?: 'button' | 'reset' | 'submit';
	size?: 'large' | 'standard' | 'small' | 'x-small';
	icon?: React.ReactNode;
	disabled?: boolean;
	className?: string;
	loading?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
	children,
	type = 'button',
	onClick,
	size = 'standard',
	icon,
	disabled,
	className,
	loading,
}) => {
	// https://github.com/yannickcr/eslint-plugin-react/issues/1555#issuecomment-474011991
	/* eslint-disable react/button-has-type */

	return (
		<button
			className={`button-container button-container-${[size]} ${className}`}
			type={type}
			onClick={!disabled ? onClick : undefined}
			disabled={disabled || loading}
		>
			<div>
				{loading && (
					<Loading className="spinner" type="spin" height="1.5rem" width="1.5rem" />
				)}
				<div className={loading ? `loading` : ''}>
					{!!icon && icon}
					{children}
				</div>
			</div>
		</button>
	);
	/* eslint-enable react/button-has-type */
};
