import {Role} from './user-role';

export interface AppDetails {
	application: {
		address: string;
		city: string;
		consent: boolean;
		customerId: number;
		dateCreated: string;
		dateOfBirth: string;
		dateUpdated: string;
		dealerId: number;
		debtFacility: string;
		email: string;
		firstName: string;
		hiProjectType: string | null;
		id: number;
		label: AppLabel | null;
		lastName: string;
		loanId: number;
		lpCustomerId: number;
		mobilePhone: string;
		monthlyIncome: number;
		orderNumber: string;
		salesPerson: string;
		selectedProductId?: number;
		ssn: string;
		state: string;
		zip: string;
		status: AppStatus;
		tmxSessionId: string | null;
		acknowledgedBy?: Role;
		acknowledgedAt: string;
		homeOwnership?: HomeOwnershipValues;
	};
	creditInformation: CreditInformation;
	offeredProducts?: Product[];
	statusHistory: StatusHistoryRecord[];
}

export interface CreditInformation {
	creditScore: string;
	creditSource: string;
	dateCreated: string;
}

export interface Product {
	deferredInterestPeriod: number;
	deferredPaymentPeriod: number;
	lineAmount: number;
	merchantDiscountRate: string;
	minimumSpend: number;
	productId: number;
	rate: string;
	termLength: number;
}

export interface StatusHistoryRecord {
	dateUpdated: string;
	status: AppStatus;
}

export enum AppStatus {
	Draft = 'draft',
	ErrorsFound = 'errors_found',
	Declined = 'declined',
	Approved = 'approved',
	DataRequested = 'data_requested',
	DocsReady = 'docs_ready',
	Accepted = 'accepted',
	Delivered = 'delivered',
	Funded = 'funded',
	Expired = 'expired',
}

export enum HomeOwnershipValues {
	Verified = 'verified',
	Unverified = 'unverified',
	Pending = 'pending',
}

export enum AppLabel {
	ECommerce = 'e-commerce',
	TTA = 'tta',
	BM = 'b&m',
}
