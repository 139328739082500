import {Colors} from '@westcreek/react';
import {AppStatus} from './applications';

export function mapStatusToColor(status: AppStatus): Colors {
	switch (status) {
		case 'draft':
			return Colors.lightGray;
		case 'approved':
			return Colors.sky;
		case 'docs_ready':
			return Colors.lilac;
		case 'accepted':
			return Colors.powderBlue;
		case 'delivered':
			return Colors.powderBlue;
		case 'declined':
			return Colors.lightTan;
		case 'funded':
			return Colors.sky;
		case 'expired':
			return Colors.lightGray;
		case 'errors_found':
			return Colors.darkStrawberry;
		case 'data_requested':
			return Colors.darkStrawberry;
		default:
			return Colors.gray;
	}
}
