import {searchCustomerApps} from '../../apis';

const searchForCustomers = (
	e: React.ChangeEvent<HTMLInputElement> | React.FormEvent,
	customerSearchValue: string | undefined,
	pageNumber: number,
) => {
	e && e.preventDefault();
	const customerSearchData = {
		SearchValue: customerSearchValue || '',
		PageNumber: pageNumber || 1,
	};
	searchCustomerApps(customerSearchData);
};

export default searchForCustomers;
