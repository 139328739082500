import React, {useMemo, useState} from 'react';
import includes from 'lodash.includes';
import {Column} from 'react-table';
import {formatToLocalDate} from '../../utils/format/format';
import {AppStatus, Product, StatusHistoryRecord} from '../../types/applications';
import {Field} from '../inputs/field';
import './app-overview.scss';
import {Toggle} from '../toggle';
import {Errors, RolePermissions, viewRolePermissions} from '../../types';
import {Button} from '../button';
import {Table} from '../table';
import {updateApplicationToDelivered} from '../../apis';
import {ErrorModal} from '../modal/error-modal';
import {typedState} from '../../store';

type LoanInfo = {
	deferredInterest: string;
	loanTerm: string;
	approvedAmount: string;
	discountRate: string;
};

const AppOverview: React.FC = () => {
	const {application, offeredProducts, statusHistory} = typedState(
		(state) => state.applications.application!,
	);
	const {loan} = typedState((state) => state.loans);
	const role = typedState((state) => state.auth.user?.role);
	const vertical = typedState((state) => state.stores.store?.vertical);
	const [checked, setChecked] = useState(
		!application
			? false
			: application.status === AppStatus.Funded || application.status === AppStatus.Delivered,
	);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<Errors | null>(null);

	const columns: Column<any>[] = useMemo(
		() => [
			{
				Header: 'Deferred Interest',
				accessor: 'deferredInterest',
			},
			{
				Header: 'Loan Term',
				accessor: 'loanTerm',
			},
			{
				Header: 'Approved Amount',
				accessor: 'approvedAmount',
			},
			{
				Header: 'Discount Rate',
				accessor: 'discountRate',
			},
			{
				Header: 'Interest Rate',
				accessor: 'interestRate',
			},
		],
		[],
	);

	const getProducts = (): Product[] => {
		let products: Product[] = [];
		const selectedProductId = application?.selectedProductId;
		if (selectedProductId) {
			const selectProduct = offeredProducts?.find((x) => x.productId === selectedProductId);
			selectProduct && products.push(selectProduct);
		} else if (offeredProducts) {
			products = offeredProducts;
		}
		return products;
	};

	const data: LoanInfo[] = useMemo(
		() =>
			getProducts().map((product) => ({
				deferredInterest: `${product.deferredInterestPeriod} months`,
				loanTerm: `${product.termLength} months`,
				approvedAmount: `$ ${product.lineAmount}.00`,
				discountRate: `${product.merchantDiscountRate}%`,
				interestRate: `${product.rate}%`,
			})),
		[],
	);

	const shouldRenderLoanInfo = (): boolean => {
		const status = application.status;

		return (
			status === AppStatus.Approved ||
			status === AppStatus.Accepted ||
			status === AppStatus.DocsReady ||
			status === AppStatus.Delivered ||
			status === AppStatus.Funded
		);
	};

	const shouldRenderItemsDelivered = () => {
		const status = application.status;
		const allowedStatuses = [AppStatus.Accepted, AppStatus.Delivered, AppStatus.Funded];

		return (
			viewRolePermissions.get(RolePermissions.ShowItemsDelivered)?.has(role!) &&
			includes(allowedStatuses, status)
		);
	};

	const handleUpdateApplicationToDelivered = async () => {
		setLoading(true);

		const error = await updateApplicationToDelivered(application.id);
		if (error) {
			setError(error);
		}
		setLoading(false);
	};

	const renderItemsDelivered = () => {
		const status = application.status;
		return (
			<div className="overview-content-item">
				<div className="d-flex align-items-center justify-content-between mb-3">
					<h6>
						{vertical === 'home_improvement' ? `Job Complete?` : `All Items Delivered?`}
					</h6>
					<div>
						<Toggle
							checked={checked}
							handleChange={() => setChecked(!checked)}
							name={AppStatus.Delivered}
							disabled={status !== AppStatus.Accepted}
						/>
					</div>
				</div>
				{checked && status === AppStatus.Accepted && (
					<div>
						<p>
							{`Application Status will change to "Ready for Funding" and Application will be submitted to Koalafi
							for funding review when you mark ${
								vertical === 'home_improvement'
									? `job complete`
									: `all items as delivered`
							}`}
						</p>
						<div className="submit-container">
							<Button
								className="submit-container-button"
								loading={loading}
								onClick={handleUpdateApplicationToDelivered}
								disabled={loading}
							>
								Submit for Funding
							</Button>
						</div>
					</div>
				)}
			</div>
		);
	};

	const renderLoanDetailInfo = () => {
		const loanAmount = loan && loan.loanAmount ? `$${loan.loanAmount}` : 'N/A';
		return (
			<div className="row col-12">
				<Field label="Loan Amount" value={loanAmount} disabled />
			</div>
		);
	};

	return (
		<div className="tab-body block-half-separeted">
			<ErrorModal error={error} setOpened={() => setError(null)} />
			<div className="row">
				<section
					className={`overview-container mb-3 mb-xl-0 ${
						shouldRenderItemsDelivered() ? `col-xl-8` : ` col-xl-12`
					}`}
				>
					{shouldRenderLoanInfo() && (
						<Table columns={columns} data={data} enableRowHeaders />
					)}
					{shouldRenderLoanInfo() && renderLoanDetailInfo()}
					<div className="row col-12">
						<Field label="Sales Rep" value={application.salesPerson} disabled />
					</div>
					<div className="row col-12">
						<Field
							label="Contract Issue Date"
							value={getContractIssueDate(statusHistory)}
							disabled
						/>
					</div>
					<div className="row col-12">
						<Field label="Order Number" value={application.orderNumber} disabled />
					</div>
				</section>
				{shouldRenderItemsDelivered() && (
					<section className="col-xl-4 form-horizontal">{renderItemsDelivered()}</section>
				)}
			</div>
		</div>
	);
};

const getContractIssueDate = (appHistory: StatusHistoryRecord[]): string | null => {
	// RAPID logic: return the first instance of "docs_ready" change
	for (let statusChange of appHistory) {
		if (statusChange.status === AppStatus.DocsReady) {
			return formatToLocalDate(statusChange.dateUpdated);
		}
	}
	return null;
};

export {AppOverview};
