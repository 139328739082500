import {AxiosError} from 'axios';
import {Action, action, thunk, Thunk} from 'easy-peasy';
import qs from 'query-string';
import {Errors} from '../../types';
import {AppDetails, AppStatus, HomeOwnershipValues} from '../../types/applications';
import {SearchCustomerAppsFilter, SearchCustomerAppsResult} from '../../types/search-apps';
import {store} from '../index';

export interface ApplicationsModel {
	application?: AppDetails;
	searchCustomerAppsResult?: SearchCustomerAppsResult;

	setApplication: Action<ApplicationsModel, AppDetails>;
	setSearchCustomerAppsResult: Action<ApplicationsModel, SearchCustomerAppsResult>;

	fetchApplication: Thunk<ApplicationsModel, number>;
	updateAppStatus: Thunk<ApplicationsModel, { status: AppStatus; applicationId: number }>;
	searchCustomerApps: Thunk<ApplicationsModel, SearchCustomerAppsFilter>;
	updateHomeOwnership: Thunk<ApplicationsModel, { homeOwnership: HomeOwnershipValues; applicationId: number }>;
}

const fetchApplication = thunk<ApplicationsModel, number>(async (actions, applicationId) => {
	const httpClient = store.getState().auth.httpClient;
	await httpClient!
		.get(`/applications/${applicationId}`)
		.then(async (applicationResponse) => {
			const {data: app}: { data: AppDetails } = applicationResponse;
			actions.setApplication(app);
		})
		.catch((e: AxiosError) => {
			console.error('unable to get application', e.response?.data);
			switch (e.response?.status) {
				case 400:
					throw new Error(Errors.InvalidRequestData);
				case 404:
					throw new Error(Errors.AppNotFound);
				case 403:
					throw new Error(Errors.Unauthorized);
				default:
					throw new Error(Errors.GeneralError);
			}
		});
});

const updateAppStatus = thunk<ApplicationsModel, { status: AppStatus; applicationId: number }>(
	async (actions, {status, applicationId}) => {
		const httpClient = store.getState().auth.httpClient;

		await httpClient!
			.post(`/apps/${applicationId}/status`, {status})
			.then(async () => {
				// refetch app to get the latest history record
				await actions.fetchApplication(applicationId).catch((e: Error) => {
					throw e;
				});
			})
			.catch((e: AxiosError) => {
				console.error('unable to update app status', e.response?.data);
				switch (e.response?.status) {
					case 400:
						throw new Error(Errors.InvalidRequestData);
					case 404:
						throw new Error(Errors.AppNotFound);
					case 403:
						throw new Error(Errors.Unauthorized);
					default:
						throw new Error(Errors.GeneralError);
				}
			});
	},
);

const updateHomeOwnership = thunk<ApplicationsModel, { homeOwnership: HomeOwnershipValues; applicationId: number }>(
	async (actions, {homeOwnership, applicationId}) => {
		const httpClient = store.getState().auth.httpClient;

		await httpClient!
			.post(`/apps/${applicationId}/hov`, {homeOwnership})
			.then(async () => {
				// refetch app to get the latest history record
				await actions.fetchApplication(applicationId).catch((e: Error) => {
					throw e;
				});
			})
			.catch((e: AxiosError) => {
				console.error('unable to update home ownership status', e.response?.data);
				switch (e.response?.status) {
					case 400:
						throw new Error(Errors.InvalidRequestData);
					case 404:
						throw new Error(Errors.AppNotFound);
					case 403:
						throw new Error(Errors.Unauthorized);
					default:
						throw new Error(Errors.GeneralError);
				}
			});
	},
);

const searchCustomerApps = thunk<ApplicationsModel, SearchCustomerAppsFilter>(
	async (actions, SearchCustomerAppsFilter) => {
		const httpClient = store.getState().auth.httpClient;

		const queries = qs.stringify(SearchCustomerAppsFilter);

		await httpClient!
			.get(`/dealers/Home/FindCustomers?${queries}`)
			.then(async (response) => {
				const {data: searchAppsResult}: { data: SearchCustomerAppsResult } = response;
				actions.setSearchCustomerAppsResult(searchAppsResult);
			})
			.catch((e: AxiosError) => {
				console.error('unable to search customer apps', e.response?.data);
				switch (e.response?.status) {
					case 400:
						throw new Error(Errors.InvalidRequestData);
					case 404:
						throw new Error(Errors.AppNotFound);
					case 403:
						throw new Error(Errors.Unauthorized);
					default:
						throw new Error(Errors.GeneralError);
				}
			});
	},
);

export const initApplicationsModel = (): ApplicationsModel => ({
	application: undefined,
	searchCustomerAppsResult: undefined,

	// actions
	setApplication: action((state, app) => {
		state.application = app;
	}),
	setSearchCustomerAppsResult: action((state, searchCustomerAppsResult) => {
		state.searchCustomerAppsResult = searchCustomerAppsResult;
	}),

	// thunks
	fetchApplication,
	updateAppStatus,
	searchCustomerApps,
	updateHomeOwnership,
});
