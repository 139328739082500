// Cookies
const JWT_COOKIE_SUFFIX = '.AspNet.JwtCookies';
const ASP_NET_SESSION_ID = 'ASP.NET_SessionId';
const APPLICATION_JWT = '.Application.Jwt';

const JWT_CLAIMS_NAME = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name';
const JWT_CLAIMS_ROLE = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';

const LEASE_APPLICATION_STATUS = {
	DRAFT: 1,
	PENDING: 2,
	APPROVED: 3,
	ERRORS_FOUND: 4,
	DECLINED: 5,
	DOCS_READY: 6,
	DOCS_SIGNED: 7,
	READY_FOR_FUNDING: 8,
	APPROVED_FOR_FUNDING: 9,
	FUNDED: 10,
	EXPIRED: 11,
	PRE_APPROVED: 12,
};

export {
	ASP_NET_SESSION_ID,
	APPLICATION_JWT,
	JWT_COOKIE_SUFFIX,
	JWT_CLAIMS_NAME,
	JWT_CLAIMS_ROLE,
	LEASE_APPLICATION_STATUS,
};
