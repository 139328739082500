/*
 * This file keeps all API handlers to handle external API requests. Each handler calls dispatch "easy-peasy" thunk
 * that processes API call and throws a formatted stringified error for all non-2XX response codes. The handler catches an
 * error and returns it to UI component. "null" will be returned in case of successful API call.
 * */

import {store} from '../store';
import {Errors, Role} from '../types';
import {AppStatus, HomeOwnershipValues} from '../types/applications';
import {SearchCustomerAppsFilter} from '../types/search-apps';

const updateApplicationToFunded = async (applicationId: number): Promise<null | Errors> => {
	const {updateAppStatus} = store.getActions().applications;
	const userRole = store.getState().auth.user!.role;

	// verify if authorized
	if (userRole === Role.Admin || userRole === Role.DealerSupportRep) {
		// update app status and update role
		try {
			await updateAppStatus({applicationId, status: AppStatus.Funded});
			return null;
		} catch (e: any) {
			return e.message as Errors;
		}
	} else {
		return Errors.UnprocessableRole;
	}
};

const updateApplicationToDelivered = async (applicationId: number): Promise<null | Errors> => {
	const {updateAppStatus} = store.getActions().applications;
	const userRole = store.getState().auth.user!.role;

	// verify if authorized
	if (
		userRole === Role.Admin ||
		userRole === Role.DealerSupportRep ||
		userRole === Role.Company ||
		userRole === Role.Group ||
		userRole === Role.Store
	) {
		// update app status and update role
		try {
			await updateAppStatus({applicationId, status: AppStatus.Delivered});
			return null;
		} catch (e: any) {
			return e.message as Errors;
		}
	} else {
		return Errors.UnprocessableRole;
	}
};

const updateApplicationToDocsReady = async (applicationId: number): Promise<null | Errors> => {
	const {updateAppStatus} = store.getActions().applications;
	const userRole = store.getState().auth.user!.role;

	// verify if authorized
	if (
		userRole === Role.Admin ||
		userRole === Role.DealerSupportRep ||
		userRole === Role.Company ||
		userRole === Role.Group ||
		userRole === Role.Store
	) {
		// update app status and update role
		try {
			await updateAppStatus({applicationId, status: AppStatus.DocsReady});
			return null;
		} catch (e: any) {
			return e.message as Errors;
		}
	} else {
		return Errors.UnprocessableRole;
	}
};

const updateApplicationToExpired = async (applicationId: number): Promise<null | Errors> => {
	const {updateAppStatus} = store.getActions().applications;
	const userRole = store.getState().auth.user!.role;

	// verify if authorized
	if (userRole === Role.Admin || userRole === Role.DealerSupportRep) {
		// update app status and update role
		try {
			await updateAppStatus({applicationId, status: AppStatus.Expired});
			return null;
		} catch (e: any) {
			return e.message as Errors;
		}
	} else {
		return Errors.UnprocessableRole;
	}
};

const updateLoanPrincipalAmount = async (
	loanId: number,
	applicationId: number,
	dealerId: string,
	loanAmount: string,
): Promise<null | Errors> => {
	const userRole = store.getState().auth.user!.role;
	const app = store.getState().applications.application;
	const {updateLoanPrincipalAmount} = store.getActions().loans;

	if (
		userRole === Role.Admin ||
		userRole === Role.DealerSupportRep ||
		userRole === Role.Company ||
		userRole === Role.Group ||
		userRole === Role.Store
	) {
		// grab selected offered product
		const selectedProduct = app?.offeredProducts?.find(
			(op) => op.productId === app?.application.selectedProductId,
		);
		if (!selectedProduct) {
			return Errors.OfferedProductNotSelected;
		}

		// validate new line amount
		if (
			+loanAmount < selectedProduct.minimumSpend ||
			+loanAmount > selectedProduct.lineAmount
		) {
			return Errors.UnprocessableLoanAmount;
		}

		try {
			await updateLoanPrincipalAmount({
				applicationId,
				loanId,
				dealerId,
				principal: loanAmount,
			});
			return null;
		} catch (e: any) {
			return e.message as Errors;
		}
	} else {
		return Errors.UnprocessableRole;
	}
};

const resendLoanAcknowledgement = async (
	dealerPublicId: string,
	applicationId: number,
): Promise<null | Errors> => {
	const {user, httpClient} = store.getState().auth;

	//	verify role
	if (
		user?.role === Role.Admin ||
		user?.role === Role.DealerSupportRep ||
		user?.role === Role.CSR ||
		user?.role === Role.Store
	) {
		// generate new OOBA link
		try {
			await httpClient!.post(`/app-orch/applications/${applicationId}/ooba/links?source=internal`, {});
			return null;
		} catch (e: any) {
			console.error(`unable to resend acknowledgment: ${e}`);
			switch (e.response?.status) {
				case 400:
					return Errors.InvalidRequestData;
				case 403:
					throw new Error(Errors.Unauthorized);
				default:
					throw new Error(Errors.GeneralError);
			}
		}
	} else {
		return Errors.UnprocessableRole;
	}
};

const searchCustomerApps = async (filters: SearchCustomerAppsFilter): Promise<Errors | null> => {
	const userRole = store.getState().auth.user!.role;
	const {searchCustomerApps} = store.getActions().applications;
	const {setLoadingStatus} = store.getActions().loading;

	const roles = [
		Role.Admin,
		Role.DealerSupportRep,
		Role.SalesManager,
		Role.SalesRep,
		Role.SalesVP,
		Role.CSR,
		Role.CSRManager,
		Role.Company,
		Role.Company,
		Role.Group,
		Role.Store,
		Role.Farmer,
	];

	setLoadingStatus(true);

	if (roles.includes(userRole)) {
		try {
			await searchCustomerApps(filters);
			setLoadingStatus(false);
			return null;
		} catch (e: any) {
			setLoadingStatus(false);
			return e.message as Errors;
		}
	} else {
		setLoadingStatus(false);
		return Errors.UnprocessableRole;
	}
};

const updateApplicationHomeOwnership = async (applicationId: number, homeOwnership: HomeOwnershipValues): Promise<null | Errors> => {
	const {updateHomeOwnership} = store.getActions().applications;
	const userRole = store.getState().auth.user!.role;

	// verify if authorized
	if (userRole === Role.Admin) {
		// update app status and update role
		try {
			await updateHomeOwnership({applicationId, homeOwnership});
			return null;
		} catch (e: any) {
			return e.message as Errors;
		}
	} else {
		return Errors.UnprocessableRole;
	}
};

export {
	updateApplicationToFunded,
	updateApplicationToDelivered,
	updateApplicationToDocsReady,
	updateApplicationToExpired,
	updateLoanPrincipalAmount,
	resendLoanAcknowledgement,
	searchCustomerApps,
	updateApplicationHomeOwnership,
};
