import * as React from 'react';
import NumberFormat from 'react-number-format';

export const Currency = (value: number | string) => (
	<NumberFormat
		value={value}
		displayType="text"
		thousandSeparator
		fixedDecimalScale
		decimalScale={2}
		prefix="$ "
	/>
);
