import React, {useEffect, useState} from 'react';
import './customer-search-box.scss';
import {Colors} from '@westcreek/react';

interface CustomerSearchBoxProps {
	isHeader?: boolean;
	onSubmit: Function;
	searchValue?: string;
}

const CustomerSearchBox: React.FC<CustomerSearchBoxProps> = ({isHeader, onSubmit, searchValue}) => {
	const [customerSearchValue, setCustomerSearchValue] = useState<string | undefined>('');

	const onFindCustomerChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		setCustomerSearchValue(e.target.value);
	};

	useEffect(() => {
		!isHeader && setCustomerSearchValue(searchValue);
	}, [searchValue]);

	return (
		<form
			onSubmit={(e) => onSubmit(e, customerSearchValue)}
			style={!isHeader ? {width: '100%', marginBottom: '1rem'} : {width: '155px'}}
			autoComplete="off"
		>
			<div className="customer-search form-field">
				<input
					type="text"
					name="findCustomer"
					className={customerSearchValue === '' ? 'form-control' : 'form-control filled'}
					id="findCustomer"
					max="100"
					value={customerSearchValue}
					onChange={onFindCustomerChange}
					style={
						!isHeader
							? {maxWidth: '100%', borderColor: Colors.lightGray}
							: {maxWidth: '155px', borderColor: 'transparent'}
					}
				/>
				<button
					type="submit"
					className="btn-search icon-search"
					style={!isHeader ? {right: '13px'} : {}}
					aria-label="Customer Search Button"
				/>
				<label htmlFor="findCustomer" className="control-label">
					{isHeader ? 'Find Customer' : 'Search'}
				</label>
			</div>
		</form>
	);
};

export default CustomerSearchBox;
