import React from 'react';
import './footer.scss';
// @ts-ignore
import {ReactComponent as PhoneIcon} from '../../assets/icons/phone.svg';

const Footer = () => {
	return (
		<footer className={'footer'}>
			<div className={'contact-info'}>
				<a href="tel:8442386500">
					<div className={'contact-info-icon-phone'}>
						<PhoneIcon title="Call Us" />
					</div>
				</a>
				<p>Dealer Support</p>
				<a href={'tel:844-238-6500'}>844-238-6500</a>
			</div>
			<div className={'company-info'}>
				<p>Koalafi | PO Box 5518 | Glen Allen, VA 23058-5518</p>
				<div>
					© Koalafi 2022 | All rights reserved |{' '}
					<a href="https://koalafi.com/privacy-policy/">Privacy Policy</a>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
