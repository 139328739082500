import React, {useEffect, useState} from 'react';
import ReactModal from 'react-modal';
import './customer-search-results-modal.scss';
import {typedState} from '../../store';
//@ts-ignore
import {ReactComponent as CloseIcon} from '../../assets/icons/x-circle.svg';
import CustomerSearchBox from '../customer-search-box';
import CustomerSearchResultsTable from '../customer-search-results-table';
import TablePagination from '../table-pagination';
import {searchForCustomers} from '../../shared';

const CustomerSearchResultsModal: React.FC = () => {
	const [modalIsOpen, setIsOpen] = useState<boolean>(false);
	const applications = typedState((state) => state.applications);
	ReactModal.setAppElement('#root');

	useEffect(() => {
		if (applications.searchCustomerAppsResult) {
			setIsOpen(true);
		}
	}, [applications.searchCustomerAppsResult]);

	const closeModal = (): void => {
		setIsOpen(false);
	};

	const customerSearch = (
		event: React.ChangeEvent<HTMLInputElement> | React.FormEvent,
		customerSearchValue?: string,
		pageNumber?: number | undefined,
	): void => {
		const newPageNumber = pageNumber || 1;
		const searchValue =
			customerSearchValue || applications.searchCustomerAppsResult?.SearchValue;
		searchForCustomers(event, searchValue, newPageNumber);
	};

	const isShowPagination = (): boolean => {
		const totalItems = applications.searchCustomerAppsResult?.PageInfo.TotalItems;
		return totalItems !== undefined && totalItems !== 0 && totalItems > 5;
	};

	return (
		<ReactModal
			isOpen={modalIsOpen}
			onRequestClose={closeModal}
			className="Modal Modal__Bootstrap modal-dialog"
			overlayClassName={'Overlay'}
			contentLabel="Customer Searchs Result Modal"
			shouldCloseOnOverlayClick={true}
			appElement={this}
			bodyOpenClassName={'modal-open'}
		>
			<div className={'modal-content'}>
				<div className="modal-header">
					<h4 className="modal-title">Find Customer</h4>
					<button type="button" className="close-modal-button" onClick={closeModal}>
						<CloseIcon title="Close Search Results Icon" role="img" />
					</button>
				</div>
				<div className="modal-body">
					<CustomerSearchBox
						onSubmit={customerSearch}
						searchValue={applications.searchCustomerAppsResult?.SearchValue}
					/>
					<CustomerSearchResultsTable
						searchCustomerAppsResult={applications.searchCustomerAppsResult}
					/>
					{isShowPagination() && (
						<TablePagination
							handlePageChange={customerSearch}
							pageInfo={applications.searchCustomerAppsResult?.PageInfo}
							className="results-pagination"
						/>
					)}
				</div>
			</div>
		</ReactModal>
	);
};

export default CustomerSearchResultsModal;
