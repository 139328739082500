import React from 'react';
import includes from 'lodash.includes';
import {Colors, ProgressBar as PB} from '@westcreek/react';
import {format} from 'date-fns';
import './progress-bar.scss';
import {Currency} from '../numbers';
import {snakeCaseToSpaces} from '../../utils';
import {Box, Boxfill} from '../icon-components';
import {AppStatus, Product, StatusHistoryRecord} from '../../types/applications';
import {mapStatusToColor} from '../../types';
import {typedState} from '../../store';

export interface IProps {
	status: AppStatus;
	offeredProducts?: Product[];
	selectedProductId: number | undefined;
	statusHistory: StatusHistoryRecord[];
	['data-testid']?: string;
}

const getValidDatesForStatusHistory = (
	statuses: AppStatus[],
	statusHistory: StatusHistoryRecord[],
) => {
	// Reverse-sort statusHistory
	if (statusHistory) {
		statusHistory.sort((a, b) => {
			const dateA = new Date(a.dateUpdated);
			const dateB = new Date(b.dateUpdated);
			if (dateA < dateB) return 1;
			if (dateA > dateB) return -1;
			return 0;
		});
	}
	// Get Zero Index of history to determine last status change
	const latestStatus = statusHistory ? statusHistory[0].status : statuses[0];
	const latestStatusIndex = statuses.indexOf(latestStatus);
	return statuses.map((status, statusIndex) => {
		if (statusIndex > latestStatusIndex) {
			return null; // If this status is further along the pipe than latest change, then ignore
		}
		const matchingStatus = statusHistory.find((x) => x.status === status);
		return matchingStatus && new Date(matchingStatus.dateUpdated);
	});
};

export const ProgressBar: React.FC<IProps> = ({
	status,
	offeredProducts,
	statusHistory,
	'data-testid': dataTestId,
}) => {
	const vertical = typedState((state) => state.stores.store?.vertical);
	const statuses: AppStatus[] = [
		AppStatus.Draft,
		AppStatus.Approved,
		AppStatus.DocsReady,
		AppStatus.Accepted,
		AppStatus.Delivered,
		AppStatus.Funded,
	];
	const statusDates = getValidDatesForStatusHistory(statuses, statusHistory);

	if (!includes(statuses, status)) return null;

	const getAnnotations = (key: number, statusItem: AppStatus) => {
		switch (statusItem) {
			case AppStatus.Approved:
				return statuses.indexOf(status) >= statuses.indexOf(AppStatus.Approved) &&
					offeredProducts!.length > 1 ? (
					<header key={key + statusItem}>
						<div
							className="annotation-header"
							data-testid={`rapid-progress-bar-approved-amount-${dataTestId}`}
						>
							{Currency(
								offeredProducts!.reduce(
									(maxVal, p) => (p.lineAmount > maxVal ? p.lineAmount : maxVal),
									offeredProducts![0].lineAmount,
								),
							)}
						</div>
						<div className="annotation-description">Approved Amount</div>
					</header>
				) : (
					<div />
				);

			case AppStatus.Delivered:
				return (
					<div className="icon-container" key={key + statusItem}>
						{statuses.indexOf(status) >= statuses.indexOf(AppStatus.Delivered) ? (
							<Boxfill />
						) : (
							<Box />
						)}
					</div>
				);
			default:
				return <div key={key} />;
		}
	};

	return (
		<div className="progress-container" data-testid={`rapid-progress-bar-${dataTestId}`}>
			<div className="progress-annotations-container">
				{statuses.map((statusItem, i) => getAnnotations(i, statusItem))}
			</div>

			<PB
				max={statuses.length}
				value={statuses.indexOf(status) + 1}
				backgroundColor={Colors.mist}
				progressColor={mapStatusToColor(status)}
				borderWidth={1}
				borderRadius={-1}
				height={1}
			/>

			<div className="progress-label-container">
				{statuses.map((statusItem: AppStatus, index: number) => {
					const date = statusDates[index];
					return (
						<div key={index + statusItem}>
							<div className="progress-label-title">
								{statusItem === 'delivered' && vertical === 'home_improvement'
									? 'JOB COMPLETE'
									: snakeCaseToSpaces(statusItem)}
							</div>
							{!!date && (
								<div>
									<div
										className="progress-label-day"
										data-testid={`rapid-progress-bar-label-${statusItem}-date`}
									>
										{format(date, 'MMMM d')}
									</div>
									<div
										className="progress-label-time"
										data-testid={`rapid-progress-bar-label-${statusItem}-time`}
									>
										{format(date, 'HH:mm')}
									</div>
								</div>
							)}
						</div>
					);
				})}
			</div>
		</div>
	);
};
