import {Action, action, Thunk, thunk} from 'easy-peasy';
import {createAxiosClient, getCookie, getCookieByFullName} from '../../utils';
import {JWT_CLAIMS_NAME, JWT_CLAIMS_ROLE, JWT_COOKIE_SUFFIX, Role} from '../../types';
import jwt_decode from 'jwt-decode';
import {AxiosInstance, AxiosRequestHeaders} from 'axios';
import {User} from '../../types/user-role';

export interface AuthModel {
	user?: User | null;
	httpClient?: AxiosInstance;
	jwtToken?: string | null;
	dealerPortalUrl?: string;
	rainUrl?: string;

	setHttpClient: Action<AuthModel, AxiosInstance>;
	setJwtToken: Action<AuthModel, string | null>;
	setDealerPortalUrl: Action<AuthModel, string>;
	setUser: Action<AuthModel, User | null>;
	setRainlUrl: Action<AuthModel, string>;

	authenticate: Thunk<AuthModel>;
}

const authenticateThunk = thunk<AuthModel>(async (actions, options) => {
	// grab dealer portal url from ENV
	const dealerPortalUrl = process.env.REACT_APP_DEALER_PORTAL_URL as string;
	const rainUrl = process.env.RAIN_URL as string;

	actions.setDealerPortalUrl(dealerPortalUrl);
	actions.setRainlUrl(rainUrl);

	// Get auth cookie set by Dealer Portal
	let [, jwt] = getCookieByFullName(dealerPortalUrl + JWT_COOKIE_SUFFIX);

	// fallback to just use suffix if issue with url config
	if (!jwt) {
		[, jwt] = getCookie(JWT_COOKIE_SUFFIX);
	}

	const headers: AxiosRequestHeaders = {};

	if (jwt) {
		headers['Authorization'] = `bearer ${jwt}`;

		const decodedJWT = jwt_decode(jwt) as {
			[index: string]: string | number;
		};

		let userRole: string;
		if (Array.isArray(decodedJWT[JWT_CLAIMS_ROLE])) {
			// @ts-ignore-line
			const roles = decodedJWT[JWT_CLAIMS_ROLE] as string[];
			if (roles.length) {
				userRole = roles[0];
			} else {
				userRole = '';
			}
		} else {
			userRole = decodedJWT[JWT_CLAIMS_ROLE] as string;
		}

		const user: User = {
			...decodedJWT,
			role: userRole as Role,
			name: decodedJWT[JWT_CLAIMS_NAME] as string,
		};
		actions.setJwtToken(jwt);
		actions.setUser(user);
	} else {
		actions.setUser(null);
		actions.setJwtToken(null);
	}
	const httpClient = createAxiosClient({headers});
	actions.setHttpClient(httpClient);
});

export const initAuthModel = (): AuthModel => ({
	httpClient: undefined,
	jwtToken: undefined,
	dealerPortalUrl: undefined,
	user: undefined,
	rainUrl: undefined,

	// actions
	setJwtToken: action((state, token) => {
		state.jwtToken = token;
	}),
	setDealerPortalUrl: action((state, url) => {
		state.dealerPortalUrl = url;
	}),
	setUser: action((state, user) => {
		state.user = user;
	}),
	setHttpClient: action((state, httpClient) => {
		state.httpClient = httpClient;
	}),
	setRainlUrl: action((state, url) => {
		state.rainUrl = url;
	}),

	// thunks
	authenticate: authenticateThunk,
});
